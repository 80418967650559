import { Component, OnInit } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Parametro } from 'src/app/models/parametro';
import { ParametroService } from 'src/app/services/parametro.service';
import { User } from 'src/app/models/token';
import { isNumber } from 'is-what';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { ApiCatalogService } from 'src/app/services/api-catalog.service';

@Component({
  selector: 'app-mant-parametros',
  templateUrl: './mant-parametros.component.html',
  styleUrls: ['./mant-parametros.component.css']
})
export class MantParametrosComponent implements OnInit {


  ListadoParametro: Parametro[];

  textToConvert: string;
  password: string;
  conversionOutput: string;

  objParametroEdit : Parametro = new Parametro();
  objParametroSel: Parametro = new Parametro();

  objuser : User = new User();
  activo: boolean;
  baja: boolean;

  flgDisplayPrincipal: boolean;
  flgnuevoeditaparam: boolean;
  flgeditarparametro:boolean;
  loading: boolean;
  lstObligatorio: SelectItem[]
  checked: boolean;
  checkedOcultar : boolean;
  estado:string;
  estadoOcultar : string;
  checked2: boolean = true;
  
  arrGlobalFilters = ['desc_parametro'];
  constructor(
    private messageService: MessageService,
    private serviceparametro:ParametroService,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
    private apiCatalog: ApiCatalogService
  ) { }

  ngOnInit() {
     this.flgDisplayPrincipal = true;
     this.blokeo = false;
     this.loading = true;
     this.getParametroAll();
     this.lstObligatorio = this.apiCatalog.lstEstadoObligatorio();
  }

  getParametroAll() {
      this.serviceparametro.getAll().subscribe(data=>{
        this.ListadoParametro = data;
        this.loading = false;
      })

  }

  bntnuevo(){
    this.flgDisplayPrincipal = false
    this.flgnuevoeditaparam = true
    this.checked = true;
    this.estado = "ACTIVO"
    this.checkedOcultar = false;
    this.estadoOcultar = "NO"
    
  }
  btncancelar(){
    this.flgDisplayPrincipal = true
    this.flgnuevoeditaparam = false
    this.flgeditarparametro = false
    this.objParametroEdit = new Parametro();
    
  }

  click() {
    if (this.activo != true) {
      this.objParametroEdit.flg_estado  = false;
    } else { 
      this.objParametroEdit.flg_estado  = true;
      this.baja = false;
    }
  }

  click1() {
    if (this.baja != true) {
      this.objParametroEdit.flg_estado  = true;
      
    } else {
      this.objParametroEdit.flg_estado  = false
      this.activo = false;
    }
  }

  GuardarParametro(){
      this.blokeo = true;

      
    
      if(this.objParametroEdit.desc_parametro == null || this.objParametroEdit.desc_parametro.length < 2 ){
        this.messageService.add({ severity: 'error', summary: 'Descripción Detallada', detail: 'Debe Ingresar mínimo 2 caracteres' });
        this.blokeo = false;
        return false;
      }

      
      if(this.objParametroEdit.desc_abreviado == null || this.objParametroEdit.desc_abreviado.length < 2 ){
        this.messageService.add({ severity: 'error', summary: 'Descripción Corta', detail: 'Debe Ingresar mínimo 2 caracteres' });
        this.blokeo = false;
        return false;
      }

      this.objParametroEdit.desc_parametro = this.objParametroEdit.desc_parametro.trim();
      this.objParametroEdit.desc_abreviado = this.objParametroEdit.desc_abreviado.trim();
      this.objParametroEdit.flg_estado = this.checked;
      this.objParametroEdit.flg_ocultar = this.checkedOcultar ? 1 : 0;

      if(this.checked2 == true){
        this.objParametroEdit.flg_obligatorio = 1
      }else if (this.checked2 == false){
        this.objParametroEdit.flg_obligatorio = 0
      }


      
    if (isNumber(this.objParametroEdit.id_parametro)) {
      console.log("SE VA EDITAR" , this.objParametroEdit)
      this.serviceparametro.update(this.objParametroEdit).subscribe(res => {
        this.getParametroAll();
        this.activo = false;
        this.baja = false;

        this.objParametroEdit = new Parametro();
        this.flgeditarparametro = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El parametro fue actualizado de forma correcta',
          confirmButtonText: 'Aceptar'
        })
      }); 
    } else {
      console.log("SE VA GUARDAR:" , this.objParametroEdit)
      this.serviceparametro.save(this.objParametroEdit).subscribe(res => {

        this.getParametroAll();
        this.activo = false;
        this.baja = false;
        this.objParametroEdit = new Parametro();
        this.flgnuevoeditaparam = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El parametro fue registrado de forma correcta',
          confirmButtonText: 'Aceptar'
        })
      }); 
    }
  }

  
  getParametroId() {
    this.serviceparametro.getById(this.objParametroSel.id_parametro).subscribe(
      data => {
        this.objParametroEdit = { ...data };
        this.blokeo = false
      });
  }

  editarParam(parametro : Parametro){
    this.blokeo = true;
    this.objParametroSel= parametro;

    this.getParametroId();
    this.flgDisplayPrincipal = false;
    this.flgeditarparametro= true;
    if (this.objParametroSel.flg_estado != false) {
      this.checked = true;
      this.estado = "ACTIVO"
    } else if (this.objParametroSel.flg_estado == false) {
      this.checked = false;
      this.estado = "BAJA"
    }
    
    if (this.objParametroSel.flg_ocultar == 1) {
      this.checkedOcultar = true;
      this.estadoOcultar = "SI"
    } else {
      this.checkedOcultar = false;
      this.estadoOcultar = "NO"
    } 


    if(this.objParametroSel.flg_obligatorio == 1){
        this.checked2 = true;
    }else if(this.objParametroSel.flg_obligatorio == 0){
        this.checked2 = false;
    }
  }

  check(){
    if(this.checked == true){
      this.estado = "ACTIVO"
    }else{
      this.estado = "BAJA"
    }
    
  }

  checkHide(){
    if(this.checkedOcultar == true){
      this.estadoOcultar = "SI"
    }else{
      this.estadoOcultar = "NO"
    }
    
  }

}
