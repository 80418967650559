import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TipoContacto } from '../models/tipoContacto';
import { SeguridadRazonSocialDocumentos } from '../models/SeguridadRazonSocialDocumentos';
import { TipoBaja } from '../models/tipoBaja';
import { HistorialCambiosContribuyentes } from '../models/historialCambiosContribuyentes';

@Injectable({
  providedIn: 'root'
})
export class TipocontactoService {

  private resource: string = environment.api_url_java + 'tipocontacto/';
  private resourceDocuments: string = environment.api_url_java + 'documentos/';
  private resourceTipoBaja: string = environment.api_url_java + 'tipoBaja/';
  private resourceHistorial: string = environment.api_url_java + 'historialCambios/';

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get<TipoContacto[]>(this.resource + 'lsttipocontacto');
  }

  getAllActivos() {
    return this.http.get<TipoContacto[]>(this.resource + 'lsttipocontactoactivo');
  }

  save(prmData: TipoContacto) {
    return this.http.post<TipoContacto>(this.resource + 'saveTipoContacto', prmData);
  }

  saveDocuments(prmData: SeguridadRazonSocialDocumentos[]) {
    return this.http.post<SeguridadRazonSocialDocumentos[]>(this.resourceDocuments + 'saveDocumentos', prmData);
  }

  listaDocumentos(ruc: string) {
    return this.http.get<SeguridadRazonSocialDocumentos[]>(this.resourceDocuments + 'lstdocumentos/'+ruc);
  }

  getAllBaja() {
    return this.http.get<TipoBaja[]>(this.resourceTipoBaja + 'lsttipobaja');
  }

  getAllActivosBaja() {
    return this.http.get<TipoBaja[]>(this.resourceTipoBaja + 'lsttipobajaactivo');
  }

  saveBaja(prmData: TipoBaja) {
    return this.http.post<TipoBaja>(this.resourceTipoBaja + 'saveTipobaja', prmData);
  }

  listaHistorial(ruc: string) {
    return this.http.get<HistorialCambiosContribuyentes[]>(this.resourceHistorial + 'lstcambios/'+ruc);
  }

  saveHistorial(prmData: HistorialCambiosContribuyentes) {
    return this.http.post<HistorialCambiosContribuyentes>(this.resourceHistorial + 'savecambios', prmData);
  }

  obtenerDocuments(prmData: SeguridadRazonSocialDocumentos) {
    return this.http.post<SeguridadRazonSocialDocumentos>(this.resourceDocuments + 'objectDocumentos', prmData);
  }
}