import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ParametroProveedorService } from 'src/app/services/parametro-proveedor.service';
import { ParametroProveedor } from 'src/app/models/parametroProveedor';
import { Proveedor } from 'src/app/models/proveedor';
import { ParametroService } from 'src/app/services/parametro.service';
import { Parametro } from 'src/app/models/parametro';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mant-parametro-proveedor',
  templateUrl: './mant-parametro-proveedor.component.html',
  styleUrls: ['./mant-parametro-proveedor.component.css']
})
export class MantParametroProveedorComponent implements OnInit {

  arrGlobalFilters = ['idProveedor.nom_rzn_social_prov'];
  flagModal : boolean = false;
  form: FormGroup;
  results: Proveedor[];
  proveedor : Proveedor;
  parametros : ParametroProveedor[];
  estado : string;
  blokeo : boolean = false;

  flagRegistrarActualizar : boolean = false;
  flagListadoParametro : boolean = true;
  parametrosBase : Parametro[];
  flg_estado_autoComplete : boolean = false;
  parametroProveedorTemporal : ParametroProveedor[] = [];
  parametroBaseRegistrar : Parametro = new Parametro();
  preveedorSelecionado : Proveedor;


  constructor( 
    private parametroProveedorService : ParametroProveedorService,
    private parametroService : ParametroService
    ) { }


  ngOnInit() {

    this.estado = 'Activo';

    this.initForm();


    this.getParametros();
  }

  initForm() {
    this.form = new FormGroup({
      'idProveedorParametro' : new FormControl(0),
      'urlBase' : new FormControl('' , Validators.required),
      'proveedor' : new FormControl('', Validators.required),
      'estado' : new FormControl(true),
      'idParametro' : new FormControl(0 , Validators.required)
    });
  }


  openCloseModalParametro(parametro : Parametro) {

    this.parametroBaseRegistrar.id_parametro = parametro.id_parametro;
    this.parametroBaseRegistrar.desc_parametro = parametro.desc_parametro;
    this.parametroBaseRegistrar.flg_estado = parametro.flg_estado;
    this.parametroBaseRegistrar.flg_obligatorio = parametro.flg_obligatorio;
    this.parametroBaseRegistrar.desc = parametro.desc;

    this.form.patchValue({
      idProveedorParametro : 0,
      urlBase : parametro.desc,
      proveedor : this.preveedorSelecionado,
      estado : true,
      idParametro : parametro
    });

    //this.initForm();
    this.flagModal = !this.flagModal;
  }

  openViewRegistrar(){
    this.flagListadoParametro = false;
    this.flagRegistrarActualizar = true;
  }


  operar() {
    let parametro = new ParametroProveedor();

    let estado = this.form.get('estado').value ? 1 : 0;

    parametro.idProveedorParametro = this.form.get('idProveedorParametro').value;
    parametro.urlBase = this.form.get('urlBase').value;
    parametro.idProveedor = this.form.get('proveedor').value;
    parametro.flgEstado = estado;
    parametro.idParametro = this.form.get('idParametro').value;

    //console.log("PARAMRETRO A GUARDAR",parametro)
    if (parametro.idProveedorParametro == 0) {
        this.closeModal();
        this.blokeo = true;
        this.parametrosBase = this.parametrosBase.filter(data => data.id_parametro != parametro.idParametro.id_parametro);

        this.parametroProveedorService.saveParametroProveedor(parametro).subscribe( data => {
           this.parametroProveedorService.findParametrosPorProveedor(parametro.idProveedor.id_proveedores)
            .subscribe(res => {
              this.parametroProveedorTemporal = res;
              this.blokeo = false;
              this.alertCustomer('success','Se registro el PARAMETRO con Exito!!', 2000,'top-end');
            });
            //console.log("Respuesta de GUARDAR",data)
        }, error => {
            //console.log(error)
            this.alertCustomer('error','ERROR Inesperado, contactar con Sistemas', 3000,'top-end');
            this.blokeo = false;
        })
    } else {
     // console.log("Aqui se actualizara el parametro")

      this.closeModal();
      this.blokeo = true;

      this.parametroProveedorService.updateParametroProveedor(parametro).subscribe(data => {

          if(data.idProveedorParametro > 0){

              this.parametroProveedorService.getParametrosProvedor().subscribe( data => {  
                this.parametros = data;
                this.blokeo = false;
                this.alertCustomer('success','Se ACTUALIZO el PARAMETRO con Exito!!', 2000,'top-end');
              });

          } else {
              this.alertCustomer('error','ERROR al actualizar PARAMETRO', 3000,'top-end');
          }
      }, error => {
        //console.log(error)
        this.alertCustomer('error','ERROR Inesperado, contactar con Sistemas', 3000,'top-end');
        this.blokeo = false;
      });
    }
  }

  listarParametrosPorProveedor(idProveedor){
    this.parametroProveedorService.findParametrosPorProveedor(idProveedor).subscribe(res => this.parametroProveedorTemporal = res );
  }

  searchProveedor(event) {
    //console.log("seachProveedor-->" + event.query)

    this.parametroProveedorService.getFilterProveedores(event.query).subscribe(data => {
      //console.log("Lo que devuelve el filter" , data)

        this.results = data;

    } , error => {
      //console.log("Error de lado del servidor..")
      this.alertCustomer('error','ERROR Inesperado, contactar con Sistemas', 3000,'top-end');
    })
  }

  proveedorSelecioando(event) {

      this.preveedorSelecionado = event;
      //console.log("Proveedor Seleccionado:", this.preveedorSelecionado)
      this.flg_estado_autoComplete = true;
      this.blokeo = true;

      this.parametroService.getAll().subscribe( parametros => {
          //this.parametrosBase = parametros;
          this.parametroProveedorService.findParametrosPorProveedor(this.preveedorSelecionado.id_proveedores)
          .subscribe( res => {

            this.parametroProveedorTemporal = res;
            //console.log("Parametros del Proveeodr:", this.parametroProveedorTemporal)
            let listaOriginal = parametros;

            this.parametroProveedorTemporal.forEach( x => {
                listaOriginal = listaOriginal.filter( paramBase =>  paramBase.id_parametro != x.idParametro.id_parametro);
            })
            this.parametrosBase = listaOriginal;
            this.blokeo = false;
          });
       
      });
  }

  addParametroProveedor(parametro : Parametro) {

    //console.log("Parametro a agregar:", parametro);

    this.parametrosBase = this.parametrosBase.filter(data => data.id_parametro != parametro.id_parametro);

    let param = new ParametroProveedor();
    param.idProveedorParametro =1;
    param.flgEstado = 1;
    param.urlBase ="null"
    param.idParametro = parametro

    this.parametroProveedorTemporal.push(param)
  }

  findParametro(id: number) {

      this.blokeo = true;

      this.parametroProveedorService.findParametroProveedor(id).subscribe(data => {

        this.parametroBaseRegistrar.desc_parametro = data.idParametro.desc_parametro;
        this.parametroBaseRegistrar.flg_estado = data.idParametro.flg_estado;
        this.parametroBaseRegistrar.flg_obligatorio = data.idParametro.flg_obligatorio;
        this.parametroBaseRegistrar.desc = data.idParametro.desc;

        this.form.patchValue({
          idProveedorParametro :data.idProveedorParametro,
          urlBase : data.urlBase,
          proveedor: data.idProveedor,
          estado : data.flgEstado,
          idParametro : data.idParametro
        })

        this.blokeo = false;

        this.flagModal = true;

      }, error => {
        this.blokeo = false;
      })
  }

  getParametros() {
    this.blokeo = true;
    this.parametroProveedorService.getParametrosProvedor().subscribe( data => { 
      this.parametros = data 
      this.blokeo = false;
    });
  }

  closeModal(){
    this.flagModal = false;
  }

  check() {
    this.estado = this.form.get('estado').value ? 'Activo' : 'No activo';
  }

  resetView() {
    this.flagListadoParametro = true;
    this.flagRegistrarActualizar = false;

    this.proveedor = new Proveedor();
    this.preveedorSelecionado = new Proveedor();
    this.flg_estado_autoComplete = false;
    this.parametrosBase = [];
    this.parametroProveedorTemporal = [];

    this.parametroProveedorService.getParametrosProvedor().subscribe( data => this.parametros = data );
  }

  resetBusqueda() {

    this.proveedor = new Proveedor();
    this.preveedorSelecionado = new Proveedor();
    this.flg_estado_autoComplete = false;
    this.parametrosBase = [];
    this.parametroProveedorTemporal = [];
  }

  alertCustomer(type : any, menssage: string, timer : number, position? :any){
    let posicion = position ? position : 'bottom-end';
    const Toast = Swal.mixin({
      toast: true, 
      position: posicion , 
      showConfirmButton: false, 
      timer: timer,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({ type: type, title: menssage })
  }

}
