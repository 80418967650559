import { Component, OnInit } from '@angular/core';
import { RznSclUsuario } from 'src/app/models/rznsclusuario';
import { MessageService } from 'primeng/api';
import { RznsclusuarioService } from 'src/app/services/rznsclusuario.service';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import { Contribuyente } from 'src/app/models/contribuyente';
import { Usuario } from 'src/app/models/usuario';
import { isNumber } from 'is-what';
import Swal from 'sweetalert2'
import { PerfilService } from 'src/app/services/perfil.service';
import { Perfil } from 'src/app/models/perfil';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Proveedor } from 'src/app/models/proveedor';
import { ProvedoresService } from 'src/app/services/provedores.service';

@Component({
  selector: 'app-mant-rznsclusuario',
  templateUrl: './mant-rznsclusuario.component.html',
  styleUrls: ['./mant-rznsclusuario.component.css'],
})
export class MantRznsclusuarioComponent implements OnInit {

  ListadoRznSocialUsuario: RznSclUsuario[];

  objRznSclUsuarioEdit: RznSclUsuario = new RznSclUsuario();
  objRznSclUsuarioSel: RznSclUsuario = new RznSclUsuario();

  activo: boolean;
  baja: boolean;
  loading: boolean;

  flgDisplayPrincipal: boolean;
  flgNuevoRznSclUsuario: boolean;
  flgEditarRznSclUsuarion: boolean;

  lstContribuyenteFiltrados: Contribuyente[];
  lstUsuarioFiltrados: Usuario[];

  lstProveedorFiltrados: Proveedor[];
  objProveedorSelected: Proveedor;


  objContribuyenteSelected: Contribuyente;
  objUsuarioSelected: Usuario;

  ListadoPerfil : Perfil[];
  ListadoperfilActivos: any[];
  objPerfilSelected : Perfil;
  checked: boolean;
  estado:string;
  arrGlobalFilters = ['proveedor.nom_rzn_social_prov','usuario.cod_usuario','proveedor.num_ruc_prov'];

  lstusuariofil:Usuario[];
  listusuarioactivos: any[];
  constructor(
    private messageService: MessageService,
    private serviceRznSclUsu: RznsclusuarioService,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
    private serviceperfil:PerfilService,
    private serviceusuario: UsuarioService,
    private serviceProveedor: ProvedoresService,
  ) { }

  ngOnInit() {
    this.flgDisplayPrincipal = true;
    this.blokeo = false;
    this.getRznSclUsuAll();
    this.getallPerfilAll();
    this.loading = true;
    this.getUsuarioAll();
  }


  getRznSclUsuAll() {
    this.serviceRznSclUsu.getAll().subscribe(data => {
      this.ListadoRznSocialUsuario = data;
      this.loading = false;
    })
  }

  getUsuarioAll(){
    this.serviceusuario.getAll().subscribe(data =>{
     this.lstusuariofil = data;
     this.listusuarioactivos = this.lstusuariofil.filter(usuario => usuario.flg_estd == "1");

    });
  }
  getallPerfilAll(){
    this.serviceperfil.getAll().subscribe(data =>{

      this.ListadoPerfil  = data;
      this.ListadoperfilActivos =  this.ListadoPerfil.filter( estado => estado.estado == 1 )
     
    });

  }

  getRznUsuId() {
    this.serviceRznSclUsu.getById(this.objRznSclUsuarioSel.id).subscribe(
      data => {
        this.objRznSclUsuarioEdit = { ...data };
        this.objProveedorSelected = this.objRznSclUsuarioEdit.proveedor;
        this.objUsuarioSelected = this.objRznSclUsuarioEdit.usuario;
        this.objPerfilSelected = this.objRznSclUsuarioEdit.perfil;
        this.blokeo = false
      });
  }

  editarRznSoclUsuario(rznsclusu:RznSclUsuario){

    this.blokeo = true;
    this.objRznSclUsuarioSel = rznsclusu;
    this.getRznUsuId();
    this.flgDisplayPrincipal = false;
    this.flgEditarRznSclUsuarion = true;
    if (this.objRznSclUsuarioSel.flg_estd != 0) {
      this.checked = true;
      this.estado = "ACTIVO"
    } else if (this.objRznSclUsuarioSel.flg_estd  == 0) {
      this.checked = false;
      this.estado = "BAJA"
    }
  

  }

  filtrarunidad(event) {
    let query = event.query;
    this.serviceProveedor.getAllProvedoresFiltro(query).subscribe(countries => {
      this.lstProveedorFiltrados = countries;
    });
  }

  bntnuevo() {
    this.flgDisplayPrincipal = false
    this.flgNuevoRznSclUsuario = true
    this.checked = true;
    this.estado = "ACTIVO"
    this.objProveedorSelected = null;
    this.objPerfilSelected = null;
    this.objUsuarioSelected = null;

  }
  btncancelar() {
    this.flgDisplayPrincipal = true
    this.flgNuevoRznSclUsuario = false
    this.flgEditarRznSclUsuarion = false
    this.objRznSclUsuarioEdit = new RznSclUsuario();
    this.baja = false;
    this.activo = false;
    this.objContribuyenteSelected = null;
    this.objPerfilSelected = null;
    this.objUsuarioSelected = null;

  }
  

  click() {
    if (this.activo != true) {
      this.objRznSclUsuarioEdit.flg_estd = 0;
    } else {
      this.objRznSclUsuarioEdit.flg_estd = 1;
      this.baja = false;
    }
  }

  click1() {
    if (this.baja != true) {
      this.objRznSclUsuarioEdit.flg_estd = 1;

    } else {
      this.objRznSclUsuarioEdit.flg_estd = 0;
      this.activo = false;
    }
  }

  filtrarempresa(event) {
    let query = event.query;
    this.serviceRznSclUsu.getAllempresaFiltro(query).subscribe(countries => {
      this.lstContribuyenteFiltrados = countries;
    });
  }

  filtrarusuario(event) {
    let query = event.query;
    this.serviceRznSclUsu.getAllusuarioFiltro(query).subscribe(countries => {
      this.lstUsuarioFiltrados = countries;
    });
  }

  GuardarRznSclUsu(){

    this.blokeo = true;


    if(this.objProveedorSelected == null || this.objProveedorSelected.id_proveedores == null ){
      this.messageService.add({ severity: 'error', summary: 'RAZON/RUC', detail: 'Debe Ingresar RAZON/RUC ' });
      this.blokeo = false;
      return false;
    }

    if(this.objUsuarioSelected == null || this.objUsuarioSelected.id_usuario == null ){
      this.messageService.add({ severity: 'error', summary: 'USUARIO', detail: 'Debe Ingresar el USUARIO' });
      this.blokeo = false;
      return false;
    }

    if(this.objPerfilSelected == null ){
      this.messageService.add({ severity: 'error', summary: 'Perfil', detail: 'Debe Ingresar el Perfil' });
      this.blokeo = false;
      return false;
    }
    if(this.checked == true){
      this.objRznSclUsuarioEdit.flg_estd = 1;
  }else{
    this.objRznSclUsuarioEdit.flg_estd = 0;
  }


    this.objRznSclUsuarioEdit.id_usuario = this.objUsuarioSelected.id_usuario;
    this.objRznSclUsuarioEdit.id_rzn_scl = this.objProveedorSelected.id_proveedores;
    this.objRznSclUsuarioEdit.fch_usu_crea = new Date();
    this.objRznSclUsuarioEdit.flg_default = 1;
    this.objRznSclUsuarioEdit.id_perfil = this.objPerfilSelected.id_perfil;

    if (isNumber(this.objRznSclUsuarioEdit.id)) {


      if(this.objRznSclUsuarioEdit.proveedor.id_proveedores != this.objProveedorSelected.id_proveedores){
      this.serviceRznSclUsu.getAllfiltro(this.objRznSclUsuarioEdit.id_rzn_scl,this.objRznSclUsuarioEdit.id_usuario).subscribe(items => {
        let flg = (items.length > 1 ? true : false);
        if (items.length == 0) {

          this.objRznSclUsuarioEdit.fch_usu_modi = new Date();
          this.serviceRznSclUsu.update(this.objRznSclUsuarioEdit).subscribe(res => {
            this.getRznSclUsuAll();
            this.activo = false;
            this.baja = false;
    
            this.objRznSclUsuarioEdit = new RznSclUsuario();
            this.flgEditarRznSclUsuarion = false;
            this.flgDisplayPrincipal = true;
            this.blokeo = false;
            Swal.fire({
              type: 'success',
              title: 'LA OPERACIÓN SE REALIZÓ CORRECTAMENTE',
              confirmButtonText: 'Aceptar'
            })
          });
          
        }
        else 
        {
          this.blokeo = false;
          this.messageService.add({key: 'custom', severity:'info', summary: '', detail: 'El usuario '+this.objUsuarioSelected.cod_usuario+ ' ya fue asignado a '+this.objProveedorSelected.nom_rzn_social_prov});
         return false;
        }
      
      });

    }else{
      this.objRznSclUsuarioEdit.fch_usu_modi = new Date();
      this.serviceRznSclUsu.update(this.objRznSclUsuarioEdit).subscribe(res => {
        this.getRznSclUsuAll();
        this.activo = false;
        this.baja = false;

        this.objRznSclUsuarioEdit = new RznSclUsuario();
        this.flgEditarRznSclUsuarion = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'LA OPERACIÓN SE REALIZÓ CORRECTAMENTE',
          confirmButtonText: 'Aceptar'
        })
      });
    }
    } else {

      this.serviceRznSclUsu.getAllfiltro(this.objRznSclUsuarioEdit.id_rzn_scl,this.objRznSclUsuarioEdit.id_usuario).subscribe(items => {
        let flg = (items.length > 1 ? true : false);
        if (items.length == 0) {
     
          this.serviceRznSclUsu.save(this.objRznSclUsuarioEdit).subscribe(res => {
            this.getRznSclUsuAll();
            this.activo = false;
            this.baja = false;
            this.objRznSclUsuarioEdit = new RznSclUsuario();
            this.flgNuevoRznSclUsuario = false;
            this.flgDisplayPrincipal = true;
            this.blokeo = false;
            Swal.fire({
              type: 'success',
              title: 'LA OPERACIÓN SE REALIZÓ CORRECTAMENTE',
              confirmButtonText: 'Aceptar'
            })
          });

        }
        else 
        {
          this.blokeo = false;
          this.messageService.add({key: 'custom', severity:'info', summary: '', detail: 'El usuario '+this.objUsuarioSelected.cod_usuario+ ' ya fue asignado a '+this.objProveedorSelected.nom_rzn_social_prov});
        }
      
      });

    }
  
  }


  check(){
    if(this.checked == true){
      this.estado = "ACTIVO"
    }else{
      this.estado = "BAJA"
    }
    
  }
}
