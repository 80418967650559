import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Parametro } from '../models/parametro';
import { Observable } from 'rxjs';
import { User } from '../models/token';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ParametroService {
  private resource: string = environment.api_url_java + 'parametro/';
  header: HttpHeaders;
  header1: HttpHeaders;

  textToConvert: string;
  password: string;
  conversionOutput: string;


  constructor(private http: HttpClient) {
    }

  getAll() {
    return this.http.get<Parametro[]>(this.resource + 'listparametro');
  }

  getById(prmId: number) {
    const url = this.resource + "paraid/" + prmId;
    return this.http.get<Parametro>(url);
  }

  save(prmData: Parametro) {
    return this.http.post<Parametro>(this.resource + 'saveparametros', prmData);
  }
  update(prmData: Parametro): Observable<any> {
    var body = prmData;
    return this.http.post<Parametro>(this.resource + 'parametrois/' + prmData.id_parametro, body)
  }

}
