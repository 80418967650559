import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { TipoBaja } from 'src/app/models/tipoBaja';
import { TipocontactoService } from 'src/app/services/tipocontacto.service';

@Component({
  selector: 'app-mant-tipobaja',
  templateUrl: './mant-tipobaja.component.html',
  styleUrls: ['./mant-tipobaja.component.css']
})
export class MantTipobajaComponent implements OnInit {

  
  ObjTipoBajaEdit: TipoBaja = new TipoBaja();
  arrGlobalFilters = ['descripcion'];
  ListadoTipoBaja: TipoBaja[];
  activo: boolean;

  constructor( private http:HttpClient,
    private serviceTipoContacto : TipocontactoService,
    public blokeo: BlockUIModule,
    private messageService: MessageService,) { }

  ngOnInit() {
    this.getTipoContactoAll();
    this.blokeo = false
    this.activo = true
  }

  
  getTipoContactoAll() {
    this.serviceTipoContacto.getAllBaja().subscribe(
      data => {
        this.ListadoTipoBaja = data;
      });
  }

  click_btnCancelar() {
    this.ObjTipoBajaEdit = new TipoBaja();
    this.activo = true;
  }

  click_btnGuardar(){
      this.blokeo = true
    if (this.ObjTipoBajaEdit.descripcion == null || this.ObjTipoBajaEdit.descripcion.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Descripción ', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    
    if (this.ObjTipoBajaEdit.valor == null || this.ObjTipoBajaEdit.valor.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Valor ', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    this.ObjTipoBajaEdit.flg_estado = this.activo ? 1 : 0; 

    this.serviceTipoContacto.saveBaja( this.ObjTipoBajaEdit).subscribe(data =>{
      this.blokeo = false
        this.ObjTipoBajaEdit = new TipoBaja();
        this.activo = true;
        this.getTipoContactoAll();
    })
      
    

  }

  Click_EditarTipoContacto(item){
      this.ObjTipoBajaEdit = item;
      this.activo = this.ObjTipoBajaEdit.flg_estado == 1 ? true : false
  }

}
