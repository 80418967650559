import { Component, OnInit } from '@angular/core';
import { TipoContacto } from '../../models/tipoContacto';
import { HttpClient } from '@angular/common/http';
import { TipocontactoService } from '../../services/tipocontacto.service';
import { BlockUIModule } from 'primeng/blockui';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-mant-tipo-contacto',
  templateUrl: './mant-tipo-contacto.component.html',
  styleUrls: ['./mant-tipo-contacto.component.css']
})
export class MantTipoContactoComponent implements OnInit {


  
  ObjTipoContactoSel: TipoContacto = new TipoContacto();
  ObjTipoContactoEdit: TipoContacto = new TipoContacto();
  arrGlobalFilters = ['descripcion'];
  ListadoTipoContacto: TipoContacto[];
  activo: boolean;

  constructor( private http:HttpClient,
    private serviceTipoContacto : TipocontactoService,
    public blokeo: BlockUIModule,
    private messageService: MessageService,) { }

  ngOnInit() {
    this.getTipoContactoAll();
    this.blokeo = false
    this.activo = true
  }

  
  getTipoContactoAll() {
    this.serviceTipoContacto.getAll().subscribe(
      data => {
        this.ListadoTipoContacto = data;
      });
  }

  click_btnCancelar() {
    this.ObjTipoContactoEdit = new TipoContacto();
    this.activo = true;
  }

  click_btnGuardar(){
      this.blokeo = true
    if (this.ObjTipoContactoEdit.descripcion == null || this.ObjTipoContactoEdit.descripcion.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Descripción ', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    
    if (this.ObjTipoContactoEdit.valor == null || this.ObjTipoContactoEdit.valor.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Valor ', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    this.ObjTipoContactoEdit.flg_estado = this.activo ? 1 : 0; 

    this.serviceTipoContacto.save( this.ObjTipoContactoEdit).subscribe(data =>{
      this.blokeo = false
        this.ObjTipoContactoEdit = new TipoContacto;
        this.activo = true;
        this.getTipoContactoAll();
    })
      
    

  }

  Click_EditarTipoContacto(item){
      this.ObjTipoContactoEdit = item;
      this.activo = this.ObjTipoContactoEdit.flg_estado == 1 ? true : false
  }
}
