import { Component, OnInit } from '@angular/core';
import { UsuarioRazonSocial } from 'src/app/models/usuarioRazonSocial';
import { UsuarioRazonSocialService } from 'src/app/services/usuario-razon-social.service';
import { MessageService } from 'primeng/api';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import { isNumber } from 'is-what';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-mant-usuario-razon-social',
  templateUrl: './mant-usuario-razon-social.component.html',
  styleUrls: ['./mant-usuario-razon-social.component.css']
})
export class MantUsuarioRazonSocialComponent implements OnInit {

  ListadoUsuarioRazonSocial: UsuarioRazonSocial[];

  objUsuarioRazonSocialEdit: UsuarioRazonSocial = new UsuarioRazonSocial();
  objUsuarioRazonSocialSel: UsuarioRazonSocial = new UsuarioRazonSocial();

  flgDisplayPrincipal: boolean;
  flgNuevoUsuarioRazonSocial: boolean;
  flgEditarUsuarioRazonSocial: boolean;
  loading: boolean;
  dateingreso: Date;

  arrGlobalFilters = ['cod_usuario','num_nif_emis'];
  constructor(
    private messageService: MessageService,
    private serviceUsuarioRazonSocial: UsuarioRazonSocialService,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
  ) { }

  ngOnInit() {

    this.flgDisplayPrincipal = true;
    this.blokeo = false;
    this.loading = true;
    this.getAllUsuarioRazon()
  }

  getAllUsuarioRazon(){
    
    this.serviceUsuarioRazonSocial.getAll().subscribe(data => {
      this.ListadoUsuarioRazonSocial = data;
      this.loading = false;
    })
  }

  editarPerfil(usuariorazon: UsuarioRazonSocial) {

    this.blokeo = true;
    this.objUsuarioRazonSocialSel = usuariorazon;
    this.getPerfilId();
    this.flgDisplayPrincipal = false;
    this.flgEditarUsuarioRazonSocial = true;
    this.dateingreso = new Date(this.objUsuarioRazonSocialSel.fec_ult_acceso)

  }
  getPerfilId() {
    this.serviceUsuarioRazonSocial.getById(this.objUsuarioRazonSocialSel.id_usuario_razon_social).subscribe(
      data => {
        this.objUsuarioRazonSocialEdit = { ...data };
        this.blokeo = false
      });
  }


  bntnuevo() {

    this.flgNuevoUsuarioRazonSocial = true;
    this.flgDisplayPrincipal = false;
    this.flgEditarUsuarioRazonSocial = false;
    this.objUsuarioRazonSocialEdit = new UsuarioRazonSocial();
    this.dateingreso = new Date()

  }

  
  btncancelar() {
    this.flgDisplayPrincipal = true
    this.flgNuevoUsuarioRazonSocial = false
    this.flgEditarUsuarioRazonSocial = false
    this.objUsuarioRazonSocialEdit = new UsuarioRazonSocial();

  }

  GuardarUsuario(){
    this.blokeo = true;

    



    if (this.objUsuarioRazonSocialEdit.cod_usuario == null || this.objUsuarioRazonSocialEdit.cod_usuario.length <= 1) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe Ingresar el Codigo' });
      this.blokeo = false;
      return false;
    }

    if (this.objUsuarioRazonSocialEdit.num_nif_emis == null) {
      this.messageService.add({ severity: 'error', summary: 'RUC', detail: 'Debe Ingresar RUC ' });
      this.blokeo = false;
      return false;
    }


    if (this.objUsuarioRazonSocialEdit.num_nif_emis.length < 11) {
      this.messageService.add({ severity: 'error', summary: 'RUC ', detail: 'Debe Ingresar 11 caracteres' });
      this.blokeo = false;
      return false;
    }

    this.objUsuarioRazonSocialEdit.cod_usuario = this.objUsuarioRazonSocialEdit.cod_usuario.trim();
    this.objUsuarioRazonSocialEdit.num_nif_emis= this.objUsuarioRazonSocialEdit.num_nif_emis.trim();

    if (isNumber(this.objUsuarioRazonSocialEdit.id_usuario_razon_social)) {
      this.serviceUsuarioRazonSocial.update(this.objUsuarioRazonSocialEdit).subscribe(res => {
        this.getAllUsuarioRazon();

        this.objUsuarioRazonSocialEdit = new UsuarioRazonSocial();
        this.flgEditarUsuarioRazonSocial = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El Usuario fue actualizado de manera correcta',
          confirmButtonText: 'Aceptar'
        })
      });
    } else {



      this.serviceUsuarioRazonSocial.getallusuario(this.objUsuarioRazonSocialEdit.cod_usuario).subscribe(items => {
        let flg = (items.length > 1 ? true : false);
        if (items.length == 0) {
          this.serviceUsuarioRazonSocial.save(this.objUsuarioRazonSocialEdit).subscribe(res => {
            this.getAllUsuarioRazon();
            this.objUsuarioRazonSocialEdit = new UsuarioRazonSocial();
            this.flgNuevoUsuarioRazonSocial = false;
            this.flgDisplayPrincipal = true;
            this.blokeo = false;
            Swal.fire({
              type: 'success',
              title: 'El Usuario  fue agregado correctamente',
              confirmButtonText: 'Aceptar'
            })
          });

        }else{
          this.blokeo = false;
          this.messageService.add({severity:'error', summary: 'Error', detail:'El codigo del Usuario ya Existe'});
          
        }
      });




    }
  }
}
