import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario';
import Swal from 'sweetalert2'
import { isNumber } from 'is-what';

@Component({
  selector: 'app-mant-usuario',
  templateUrl: './mant-usuario.component.html',
  styleUrls: ['./mant-usuario.component.css']
})
export class MantUsuarioComponent implements OnInit {

  flgDisplayPrincipal: boolean;
  flgNuevoUsuario:boolean;
  flgEditarUsuario:boolean;

  correo:number;

  objUsuarioEdit: Usuario = new Usuario();
  objUsuarioSel: Usuario = new Usuario();

  dateCaducidad: Date;
  activo: boolean;
  loading: boolean;
  baja: boolean;
  checked: boolean;
  estado:string;
  arrGlobalFilters = ['cod_usuario','txt_nom_cmp_usuario'];

  ListadoUsuario: Usuario[];
  constructor(
    private messageService: MessageService,
    private serviceusuario: UsuarioService,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
  ) { }

  ngOnInit() {
    this.flgDisplayPrincipal = true;
    this.blokeo = false;
    this.getUsuarioAll();
    this.loading = true;
  }

  
  getUsuarioAll() {
    this.serviceusuario.getAll().subscribe(data => {
      this.ListadoUsuario = data;
      this.loading = false;
    })
  }

  getUsuarioId() {
    this.serviceusuario.getById(this.objUsuarioSel.id_usuario).subscribe(
      data => {
        this.objUsuarioEdit = { ...data };
        this.blokeo = false
      });
  }


  editarUsuario(usuario: Usuario){

    this.blokeo = true;
    this.objUsuarioSel = usuario;
    this.getUsuarioId();
    this.flgDisplayPrincipal = false;
    this.flgEditarUsuario = true;
    this.flgNuevoUsuario = false;
    this.dateCaducidad = new Date(this.objUsuarioSel.fec_expira)

    if (this.objUsuarioSel.flg_estd != "0") {
      this.checked = true;
      this.estado = "ACTIVO"
    } else if (this.objUsuarioSel.flg_estd == "0") {
      this.checked = false;
      this.estado = "BAJA"
    } 
  }

  GuardarUsuario(){

    this.blokeo = true;

    if (this.objUsuarioEdit.cod_usuario == null || this.objUsuarioEdit.cod_usuario.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Usuario', detail: 'Debe Ingresar 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    if (isNumber(this.objUsuarioEdit.id_usuario)) {

  }else{
    if(this.objUsuarioEdit.txt_pass_usuario == null ||this.objUsuarioEdit.txt_pass_usuario.length < 8){

      this.messageService.add({ severity: 'error', summary: 'Contraseña', detail: 'Debe Ingresar 8 caracteres' });
      this.blokeo = false;
      return false;
    }else{
      this.validar_clave();
    }
  }


    if (this.objUsuarioEdit.txt_pass_usuario == null ||this.objUsuarioEdit.txt_pass_usuario.length < 8) {
      this.messageService.add({ severity: 'error', summary: 'Contraseña', detail: 'Debe Ingresar 8 caracteres' });
      this.blokeo = false;
      return false;
    }


    if (this.objUsuarioEdit.txt_nom_cmp_usuario == null ||this.objUsuarioEdit.txt_nom_cmp_usuario.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Nombres', detail: 'Debe Ingresar 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    
    if (this.objUsuarioEdit.txt_ape_pat_usuario == null ||this.objUsuarioEdit.txt_ape_pat_usuario.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Apellido Paterno', detail: 'Debe Ingresar 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    if (this.objUsuarioEdit.txt_ape_mat_usuario == null ||this.objUsuarioEdit.txt_ape_mat_usuario.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Apellido Materno', detail: 'Debe Ingresar 2 caracteres' });
      this.blokeo = false;
      return false;
    }
    
    if(this.objUsuarioEdit.txt_desc_email != null){
      this.blokeo = true
      this.validarEmail()
      if(this.correo == 1){
        this.messageService.add({ severity: 'error', summary: 'Correo', detail: 'La dirección de email ' + this.objUsuarioEdit.txt_desc_email + ' es incorrecta.' });
        this.blokeo = false;
        return false;
      }
    }

    if(this.checked == true){
      this.objUsuarioEdit.flg_estd = "1";
  }else{
    this.objUsuarioEdit.flg_estd = "0";
  }



    let mes_emision: number = this.dateCaducidad.getMonth();
    mes_emision = mes_emision + 1;
    let dia_emision: number = this.dateCaducidad.getDate();
    this.objUsuarioEdit.fec_expira = this.dateCaducidad.getFullYear() + '-' + ('0000' + mes_emision).slice(-2) + '-' + ('0000' + dia_emision).slice(-2)

    var date = new Date();
    let mes_emision_hoy: number = date.getMonth();
    mes_emision = mes_emision + 1;
    let dia_emision_hoy: number = date.getDate();
   this.objUsuarioEdit.flg_bloq = "0";


    this.objUsuarioEdit.cod_usuario = this.objUsuarioEdit.cod_usuario.trim();
    this.objUsuarioEdit.txt_pass_usuario = this.objUsuarioEdit.txt_pass_usuario.trim();
    this.objUsuarioEdit.txt_nom_cmp_usuario = this.objUsuarioEdit.txt_nom_cmp_usuario.trim();
    this.objUsuarioEdit.txt_ape_pat_usuario = this.objUsuarioEdit.txt_ape_pat_usuario.trim();
    this.objUsuarioEdit.txt_ape_mat_usuario = this.objUsuarioEdit.txt_ape_mat_usuario.trim();
    this.objUsuarioEdit.txt_desc_email = this.objUsuarioEdit.txt_desc_email.trim();

    if (isNumber(this.objUsuarioEdit.id_usuario)) {
      this.serviceusuario.update(this.objUsuarioEdit).subscribe(res => {
        this.getUsuarioAll();
        this.activo = false;
        this.baja = false;

        this.objUsuarioEdit = new Usuario();
        this.flgEditarUsuario= false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El Usuario fue Actualizado de manera correcta',
          confirmButtonText: 'Aceptar'
        })
      });
    } else {
      this.objUsuarioEdit.fec_crea = date.toISOString().replace('T', ' ').replace('Z', '');
   
      this.serviceusuario.getAllusuario(this.objUsuarioEdit.cod_usuario).subscribe(items => {
        let flg = (items.length > 1 ? true : false);
        if (items.length == 0) {
          this.serviceusuario.save(this.objUsuarioEdit).subscribe(res => {
            this.getUsuarioAll();
            this.activo = false;
            this.baja = false;
            this.objUsuarioEdit = new Usuario();
            this.flgNuevoUsuario = false;
            this.flgDisplayPrincipal = true;
            this.blokeo = false;
            Swal.fire({
              type: 'success',
              title: 'El Usuario fue Agregado Correctamente',
              confirmButtonText: 'Aceptar'
            })
          });
        }
        else 
        {
          this.blokeo = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario '+this.objUsuarioEdit.cod_usuario +' ya existe ' });
        }
      
      });


    }
  }

  btncancelar(){
    this.flgDisplayPrincipal = true
    this.flgNuevoUsuario = false
    this.flgEditarUsuario = false;
    this.objUsuarioEdit = new Usuario();
  }

  btnNuevo(){
    this.flgDisplayPrincipal = false;
    this.flgNuevoUsuario = true;
    this.flgEditarUsuario = false;
    this.dateCaducidad = new Date();
    this.checked = true;
    this.estado = "ACTIVO"
    this.objUsuarioEdit = new Usuario();
  }

  
  click() {
    if (this.activo != true) {
      this.objUsuarioEdit.flg_estd = "0";
    } else {
      this.objUsuarioEdit.flg_estd = "1";
      this.baja = false;
    }
  }

  click1() {
    if (this.baja != true) {
      this.objUsuarioEdit.flg_estd = "1";

    } else {
      this.objUsuarioEdit.flg_estd = "0";
      this.activo = false;
    }
  }

  validarEmail() {

    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.objUsuarioEdit.txt_desc_email)){
     this.blokeo= true;
     this.correo= 0;
    } else {
      this.blokeo= true;
     this.correo = 1;
    }
  }

  validar_clave()
		{

			if(this.objUsuarioEdit.txt_pass_usuario.length >= 8)
			{		
				var mayuscula = false;
				var minuscula = false;
				var numero = false;
				
				for(var i = 0;i<this.objUsuarioEdit.txt_pass_usuario.length;i++)
				{
					if(this.objUsuarioEdit.txt_pass_usuario.charCodeAt(i) >= 65 && this.objUsuarioEdit.txt_pass_usuario.charCodeAt(i) <= 90)
					{
						mayuscula = true;
					}
					else if(this.objUsuarioEdit.txt_pass_usuario.charCodeAt(i) >= 97 && this.objUsuarioEdit.txt_pass_usuario.charCodeAt(i) <= 122)
					{
						minuscula = true;
					}
					else if(this.objUsuarioEdit.txt_pass_usuario.charCodeAt(i) >= 48 && this.objUsuarioEdit.txt_pass_usuario.charCodeAt(i) <= 57)
					{
						numero = true;
					}
				}
				if(mayuscula == true && minuscula == true  && numero == true)
				{
					return true;
				}
      }
      this.messageService.add({ severity: 'error', summary: 'Contraseña', detail: 'Debe utilizar una combinación de minúsculas , mayúsculas y números como mínimo. ' });
      return false;
    }

    NuevaContraseña(){
      this.objUsuarioEdit.txt_pass_usuario = "";
    }

    check(){
      if(this.checked == true){
        this.estado = "ACTIVO"
      }else{
        this.estado = "BAJA"
      }
      
    }
}
