export class MenuOpcion {
    id: number;
    idpadre: number = 1;
    descripcion: string;
    idperfil: number;

    ruta: string;
    estado: number;
    numeroorden: number;
    icono: string;

    constructor(pid?: number,
        pidpadre?: number,
        pdescripcion?: string,
        pidperfil?: number,
        pruta?: string,
        pestado?: number,
        pnumeroorden?: number,
        picono?: string
    ) {
        this.id = pid;
        this.idpadre = pidpadre;
        this.descripcion = pdescripcion;
        this.idperfil = pidperfil;
        this.ruta = pruta;
        this.estado = pestado;
        this.numeroorden = pnumeroorden;
        this.icono = picono;
    }

}