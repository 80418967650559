export class Menu {

    id_OPCION: string;
    txt_DESC_OPC:string;
    txt_URL_OPC:string;
    opc_ID_OPCION:string;
    num_ORDEN:string;
    cod_usuario:string;
    num_nif_emis:string;
    nom_rzn_social:string;
    url_icon:string;
}