import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Contribuyente } from '../models/contribuyente';
import { Observable } from 'rxjs';
import { Parametro } from '../models/parametro';
import { SeguridadParametro } from '../models/seguridadparametro';
import { ArchivoRespDocumento } from '../models/archivoRespDocumento';
import { ContribuyenteSend } from '../models/contribuyenteSend';
import { WsDocumentoPago } from '../models/wsDocumentoPago';
import { ConsultaPageableDTO } from '../models/consultaPageableDTO';
import { Planes } from '../models/planes';
import { RecoverPassword } from '../models/recoverpassword';
import { RznSclCantCpe } from '../models/rznsclcantcpe';
import { LugarVehiculoEmpresaAll } from '../models/lugarVehiculoEmpresaAll';

@Injectable({
  providedIn: 'root'
})
export class ContribuyenteService {


  private resource: string = environment.api_url_java + 'seguridadrazonsocial/';
  private resource1: string = environment.api_url_java + 'rznsclusuario/';
  private urlcrznsclcant: string = environment.api_url_java + 'rznsclcant/';
  private urlmarker: string = environment.api_url_java + 'lugar/';
  header: HttpHeaders;
  
  textToConvert: string;
  password: string;
  conversionOutput: string;

  constructor(private http: HttpClient) {
   }

   getAll() {
    return this.http.get<Contribuyente[]>(this.resource + 'listseguridadrazon');
  }

  getById(prmId: number) {
    const url = this.resource +"seguridadrazonid/"+ prmId;
    return this.http.get<Contribuyente>(url);
  }

  save(prmData: Contribuyente) {
    return this.http.post<Contribuyente>(this.resource + 'saveseguridadrazon', prmData);
  }
  update(prmData: Contribuyente): Observable<any> {
    var body = prmData;
    return this.http.post<Contribuyente>(this.resource+ 'seguridadrazonsupdate/' + prmData.id_seguridad, body)
  }

  PlanContribuyente(num_nif_emis:string){
    return this.http.get<Planes>(this.resource+ 'plancontribu/' + num_nif_emis,);
  }

  listparametro(prmData: Parametro[]) {
    return this.http.post<Parametro[]>(this.resource + 'listparametro', prmData);
  }

  getAllParametro(ruc:string) {
    return this.http.get<SeguridadParametro[]>(this.resource + 'listcontriparam/'+ruc ,);
  }

  listcontribyenteparam(prmData: SeguridadParametro[]){
    return this.http.post<SeguridadParametro[]>(this.resource + 'contribuyenteparamupdate', prmData);
  }

  getAllfiltro(ruc:string) {
    return this.http.get<Contribuyente[]>(this.resource1 + 'listfiltrocontribuyente/'+ruc);
  }

  getAllrucpro(ruc:string) {
    return this.http.get<Contribuyente[]>(this.resource + 'listproveruc/'+ruc);
  }


  saveContribuyente(url:string,prmData:ContribuyenteSend){
    return this.http.post<ArchivoRespDocumento>(url+ 'EmpresaNewMifact', prmData);

  }

  UpdateContribuyente(url:string,prmData:ContribuyenteSend){
    return this.http.post<ArchivoRespDocumento>(url+ 'EmpresaUpdateMifact', prmData);

  }

  vistaPreviaSend(url:string,prmData:WsDocumentoPago){
    return this.http.post<ArchivoRespDocumento>(url+ 'SendInvoicePreview', prmData);
  }

  vistaPreviaSendGuia(url:string,prmData:WsDocumentoPago){
    return this.http.post<ArchivoRespDocumento>(url+ 'SendGuiaPreview', prmData);
  }
  

  DescargarPlantilla(url:string,prmData:WsDocumentoPago){
    return this.http.post<ArchivoRespDocumento>(url+ 'DowTemplateMifact', prmData);
  }

  UploadPlantilla(url:string,prmData:WsDocumentoPago){
    return this.http.post<ArchivoRespDocumento>(url+ 'UpTemplateMifact', prmData);
  }

  UploadImage(prmData:Contribuyente){
    return this.http.post<Contribuyente>(this.resource + 'uploadimage', prmData);
  }

  downloadContribuyentes( rucProveedor : string ) {
    return this.http.post(`${this.resource}downloadContribuyentes`, rucProveedor,{
      responseType: 'blob'
    });
  }

  getContribuyenteFilterPaginado( filter : string, rucProveedor :string , page) {
    return this.http.get<ConsultaPageableDTO>(`${this.resource}contribuyentes/${filter}/${rucProveedor}?page=${page}&size=10`);
  }
  downloadCantCPEContribuyentes( p_rucProveedor : string, p_periodo : number ) {
    return this.http.post(`${this.resource}downloadCantCPEContribuyentes`, { rucProveedor: p_rucProveedor,periodo:p_periodo },{
      responseType: 'blob'
    });
  }

  CorreoCodigoVerificacion(prmData:RecoverPassword) : Observable<ArchivoRespDocumento> {
    return this.http.post<ArchivoRespDocumento>("https://demo.mifact.net.pe/api/Security.svc/EnviarCorreoMifact", prmData);

  }

  ListCantidaCpe(num_nif_emis:string){
    return this.http.get<RznSclCantCpe[]>(this.urlcrznsclcant+ 'cantidad/' + num_nif_emis,);
  }

  ListMarker(num_nif_emis:string){
    return this.http.get<LugarVehiculoEmpresaAll[]>(this.urlmarker+ 'lstavenidaEmpresas/' + num_nif_emis,);
  }

  saveMarker(prmData: LugarVehiculoEmpresaAll){
    return this.http.post<LugarVehiculoEmpresaAll>(this.urlmarker + 'savelugar', prmData);
  }
}
