import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Proveedor } from '../models/proveedor';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { ProveedorDTO } from '../models/proveedorDTO';

@Injectable({
  providedIn: 'root'
})
export class ProvedoresService {
  private resource: string = environment.api_url_java + 'proveedores/';
  header: HttpHeaders;

  textToConvert: string;
  password: string;
  conversionOutput: string;

  constructor(private http: HttpClient) {
    
   }

   getAll() {
    return this.http.get<Proveedor[]>(this.resource + 'listproveedores');
  }

  getById(prmId: number) {
    const url = this.resource +"proveedoresid/"+ prmId;
    return this.http.get<Proveedor>(url);
  }

  save(prmData: Proveedor) {
    return this.http.post<Proveedor>(this.resource + 'saveproveedores', prmData);
  }
  update(prmData: Proveedor): Observable<any> {
    var body = prmData;
    return this.http.post<Proveedor>(this.resource+ 'proveedoresupdate/' + prmData.id_proveedores, body)
  }

  getallProveedor(proveedor:string){
    return this.http.get<Proveedor[]>(this.resource + 'listproveedores/'+ proveedor );
  
  }

  getAllProvedoresFiltro(desc:string) {
    console.log("this.resource: ", this.resource);
    
    return this.http.get<Proveedor[]>(this.resource + 'listproveedoresfiltro/'+ desc);
  }

  getProveedoresParametros(ruc:string) {
    return this.http.get<ProveedorDTO[]>(this.resource + 'lstProveedoresParametros/'+ ruc);
  }

}
