import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { MenuOpcion } from '../models/menuOpcion';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PerfilesService {

  header: HttpHeaders;

  private resource: string = environment.api_url_java + 'perfiles/';
  password: string;
  constructor(private http: HttpClient,
) {
   }

  addOpcion(prmData: MenuOpcion) {
    return this.http.post<MenuOpcion>(this.resource + 'additemmenu', prmData);
  }

  delOpcion(prmData: MenuOpcion) {
    return this.http.get<MenuOpcion>(this.resource + 'delitemmenu/' + prmData.idperfil + '/' + prmData.id);
  }
}
