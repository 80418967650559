import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStoreService } from './local-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url_oaut: string = `${environment.HOST}/oauth/token`;
  url_auth: string = `${environment.HOST}/api/authentication`;
  url_logout: string = `${environment.HOST}/api/tokens`;
  authenticated = true;
  constructor(
    private http: HttpClient,
    private store: LocalStoreService, 
    private router: Router) 
  {
      //this.checkAuth(); 
  }

  
  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
    let token = this.store.getItem(environment.TOKEN_NAME);
    return token != null;
  }
 

  signin(usuario: string, contrasena: string) {

    this.authenticated = true;

    const body = `grant_type=password&username=${encodeURIComponent(usuario)}&password=${encodeURIComponent(contrasena)}`;

    return this.http.post(this.url_oaut, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8').set('Authorization', 'Basic ' + btoa(environment.TOKEN_AUTH_USERNAME + ':' + environment.TOKEN_AUTH_PASSWORD))
    });
    
  }

  signout() {
    this.authenticated = false;
    console.log("CERRO SESSION")
    //this.store.setItem("demo_login_status", false);
    //this.router.navigateByUrl("/sessions/signin");
    let access_token = JSON.parse(this.store.getItem(environment.TOKEN_NAME)).access_token;
    this.http.get(`${this.url_logout}/anular/${access_token}`).subscribe(() => {
      this.store.clear();
      this.router.navigate(['sessions/signin']);
    });
  }

  

}
