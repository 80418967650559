export class ActualizacionApp {
    id:number;
    version:string= "";
    version_ios:string= "";
    fecha_version:string
    flg_activo:number;
    flg_estado: boolean;
    version_web:string= "";
    version_huawei:string = "";
    aplicacion:string
}