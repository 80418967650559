export class VwUsuarioRazon {

    id_usuario:number;
    cod_usuario:string;
    id_rzn_scl:number;
    num_ruc_prov:string;
    nom_rzn_social_prov:string;
    id_perfil:number;
    txt_pass_usuario:string

}