import { Component, OnInit } from '@angular/core';
import { Perfil } from 'src/app/models/perfil';
import { MessageService } from 'primeng/api';
import { PerfilService } from 'src/app/services/perfil.service';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import Swal from 'sweetalert2'
import { isNumber } from 'is-what';

@Component({
  selector: 'app-mant-perfil',
  templateUrl: './mant-perfil.component.html',
  styleUrls: ['./mant-perfil.component.css']
})
export class MantPerfilComponent implements OnInit {

  ListadoPerfil: Perfil[];

  objPerfilEdit: Perfil = new Perfil();
  objPerfilSel: Perfil = new Perfil();

  activo: boolean;
  baja: boolean;

  flgDisplayPrincipal: boolean;
  flgNuevoPerfil:boolean;
  flgEditarPerfil:boolean;
  loading: boolean;
  checked: boolean;
  estado:string;

  arrGlobalFilters = ['cod_perfil','text_perfil'];

  constructor(
    private messageService: MessageService,
    private serviceperfil: PerfilService,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
  ) { }

  ngOnInit() {
    this.flgDisplayPrincipal = true;
    this.blokeo = false;
    this.loading = true;
    this.getPerfilAll();
  }

  getPerfilAll() {
    this.serviceperfil.getAll().subscribe(data => {
      this.ListadoPerfil = data;
      this.loading = false;
    })
  }

  editarPerfil(perfil : Perfil){

    this.blokeo = true;
    this.objPerfilSel = perfil;
    this.getPerfilId();
    this.flgDisplayPrincipal = false;
    this.flgEditarPerfil = true;
    if (this.objPerfilSel.estado != 0) {
      this.checked = true;
      this.estado = "ACTIVO"
    } else if (this.objPerfilSel.estado == 0) {
      this.checked = false;
      this.estado = "BAJA"
    } else {
    }

  }

  bntnuevo(){

    this.flgNuevoPerfil = true;
    this.flgDisplayPrincipal = false;
    this.flgEditarPerfil = false;
    this.checked = true;
    this.estado = "ACTIVO"
    this.objPerfilEdit = new Perfil();

  }

  click() {
    if (this.activo != true) {
      this.objPerfilEdit.estado = 0;
    } else {
      this.objPerfilEdit.estado = 1;
      this.baja = false;
    }
  }

  click1() {
    if (this.baja != true) {
      this.objPerfilEdit.estado = 1;

    } else {
      this.objPerfilEdit.estado = 0;
      this.activo = false;
    }
  }

  GuardarPerfil(){

    this.blokeo = true;


    this.objPerfilEdit.cod_perfil= this.objPerfilEdit.cod_perfil.trim();
    this.objPerfilEdit.text_perfil = this.objPerfilEdit.text_perfil.trim();

    
    if (this.objPerfilEdit.cod_perfil == null || this.objPerfilEdit.cod_perfil.length < 3) {
      this.messageService.add({ severity: 'error', summary: 'Codigo', detail: 'Debe Ingresar mínimo 3 caracteres' });
      this.blokeo = false;
      return false;
    }

    if (this.objPerfilEdit.text_perfil == null || this.objPerfilEdit.text_perfil.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Nombre', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    if(this.checked == true){
      this.objPerfilEdit.estado = 1;
  }else{
    this.objPerfilEdit.estado = 0;
  }


    if (isNumber(this.objPerfilEdit.id_perfil)) {
      this.serviceperfil.update(this.objPerfilEdit).subscribe(res => {
        this.getPerfilAll();
        this.activo = false;
        this.baja = false;

        this.objPerfilEdit = new Perfil();
        this.flgEditarPerfil = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El Perfil fue actualizado de manera correcta',
          confirmButtonText: 'Aceptar'
        })
      });
    } else {
      this.serviceperfil.save(this.objPerfilEdit).subscribe(res => {
        this.getPerfilAll();
        this.activo = false;
        this.baja = false;
        this.objPerfilEdit = new Perfil();
        this.flgNuevoPerfil = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El Perfil fue agregado correctamente',
          confirmButtonText: 'Aceptar'
        })
      });

    }
  }

  getPerfilId() {
    this.serviceperfil.getById(this.objPerfilSel.id_perfil).subscribe(
      data => {
        this.objPerfilEdit = { ...data };
        this.blokeo = false
      });
  }

  btncancelar() {
    this.flgDisplayPrincipal = true
    this.flgNuevoPerfil = false
    this.flgEditarPerfil = false
    this.objPerfilEdit = new Perfil();

  }
  check(){
    if(this.checked == true){
      this.estado = "ACTIVO"
    }else{
      this.estado = "BAJA"
    }
    
  }
}
