import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { Opcion } from '../models/opcion';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpcionService {

  private resource: string = environment.api_url_java + 'opcion/';
  header: HttpHeaders;
  
  textToConvert: string;
  password: string;
  conversionOutput: string;

  constructor(private http: HttpClient) {
   }

   getAll() {
    return this.http.get<Opcion[]>(this.resource + 'listopcion');
  }

  getById(prmId: number) {
    const url = this.resource +"opcionid/"+ prmId;
    return this.http.get<Opcion>(url);
  }

  save(prmData: Opcion) {
    return this.http.post<Opcion>(this.resource + 'saveopcion', prmData);
  }
  update(prmData: Opcion): Observable<any> {
    var body = prmData;
    return this.http.post<Opcion>(this.resource+ 'opcionupdate/' + prmData.id_opcion, body)
  }
  getAllUrl() {
    return this.http.get<Opcion[]>(this.resource + 'listopcionurl');
  }

  getAllfiltro(desc:string) {
    return this.http.get<Opcion[]>(this.resource + 'listopcionfiltro/'+desc);
  }
}
