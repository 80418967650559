import { Component, OnInit } from '@angular/core';
import { Opcion } from 'src/app/models/opcion';
import { MessageService } from 'primeng/api';
import { OpcionService } from 'src/app/services/opcion.service';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import Swal from 'sweetalert2'
import { isNumber } from 'is-what';
import * as _ from 'lodash';

@Component({
  selector: 'app-mant-opcion',
  templateUrl: './mant-opcion.component.html',
  styleUrls: ['./mant-opcion.component.css']
})
export class MantOpcionComponent implements OnInit {


  ListadoOpcion : Opcion[];
  rowGroupMetadata: any;

  objOpcionEdit: Opcion = new Opcion();
  objOpcionSel: Opcion = new Opcion();
  checked: boolean;
  estado:string;
  activo: boolean;
  baja: boolean;
  ListadoUrlOpcion : Opcion[];
  urlOpcionSelected:Opcion;
  flgDisplayPrincipal: boolean;
  flgNuevoOpcion: boolean;
  flgEditarOpcion: boolean;
  loading: boolean;
  arrGlobalFilters = ['txt_desc_opc'];
  cols: any[];

  filtro:string;
  constructor(
    private messageService: MessageService,
    private serviceOpcion: OpcionService ,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
  ) { }

  ngOnInit() {
    this.flgDisplayPrincipal = true;
    this.blokeo = false;
    this.loading = true;
    this.getOpcionAll();
    this.getAllUrlOpcion();
    this.updateRowGroupMetaData();

  }


  getOpcionAll() {
    this.serviceOpcion.getAll().subscribe(data => {
      this.ListadoOpcion = data;
      this.loading = false;
      this.ListadoOpcion =  _.orderBy(this.ListadoOpcion, ['num_orden'], ['asc']);
      this.updateRowGroupMetaData();

    })
  }

getOpcionAllfiltro(){
  if(this.filtro.length >= 1){
  this.serviceOpcion.getAllfiltro(this.filtro).subscribe(data => {
    this.ListadoOpcion = data;
    this.loading = false;
    this.ListadoOpcion =  _.orderBy(this.ListadoOpcion, ['num_orden'], ['asc']);
    this.updateRowGroupMetaData();

  })
}else{
  this.getOpcionAll();
}
}
  
  bntnuevo() {
    this.flgDisplayPrincipal = false
    this.flgNuevoOpcion = true
    this.checked = true;
    this.estado = "ACTIVO"
    this.urlOpcionSelected = null
  }
  btncancelar() {
    this.flgDisplayPrincipal = true
    this.flgNuevoOpcion = false
    this.flgEditarOpcion = false
    this.objOpcionEdit = new Opcion();

  }

  click() {
    if (this.activo != true) {
      this.objOpcionEdit.flg_estd = 0;
    } else {
      this.objOpcionEdit.flg_estd = 1;
      this.baja = false;
    }
  }

  click1() {
    if (this.baja != true) {
      this.objOpcionEdit.flg_estd = 1;

    } else {
      this.objOpcionEdit.flg_estd = 0;
      this.activo = false;
    }
  }

  getOpcionId() {
    this.serviceOpcion.getById(this.objOpcionSel.id_opcion).subscribe(
      data => {
        this.objOpcionEdit = { ...data };
        this.urlOpcionSelected = this.objOpcionEdit.opcion;
        this.blokeo = false
      });
  }

  getOpcionIdCabecera(){
    this.serviceOpcion.getById(this.objOpcionSel.opc_id_opcion).subscribe(
      data => {
        this.objOpcionEdit = { ...data };

        if(this.objOpcionEdit.txt_url_opc == "#"){
          document.getElementById("urls").setAttribute("disabled", "")
        }
        
        this.urlOpcionSelected = this.objOpcionEdit.opcion;
        this.blokeo = false
      });

  }
  editarOpcion(opcion: Opcion) {

    this.activo = false;
    this.baja = false;
    this.blokeo = true;
    this.objOpcionSel = opcion;
    this.getOpcionId();
    this.flgDisplayPrincipal = false;
    this.flgEditarOpcion = true;

    if (this.objOpcionSel.flg_estd != 0) {
      this.checked = true;
      this.estado = "ACTIVO"
    } else if (this.objOpcionSel.flg_estd == 0) {
      this.checked = false;
      this.estado = "BAJA"
    } else {
    }
  }

  GuardarOpcion(){

this.blokeo = true;

if (this.objOpcionEdit.txt_desc_opc == null || this.objOpcionEdit.txt_desc_opc.length < 2) {
  this.messageService.add({ severity: 'error', summary: 'Nombre de la Opcion', detail: 'Debe Ingresar mínimo 2 caracteres' });
  this.blokeo = false;
  return false;
}


if(this.urlOpcionSelected == null ){
  this.messageService.add({ severity: 'error', summary: 'Categoria', detail: 'Debe Ingresar la Categoria' });
  this.blokeo = false;
  return false;
}


if (this.objOpcionEdit.txt_url_opc == null || this.objOpcionEdit.txt_url_opc.length < 1) {
  this.messageService.add({ severity: 'error', summary: 'URL de la Opcion', detail: 'Debe Ingresar mínimo 1 caracteres' });
  this.blokeo = false;
  return false;
}


if(this.checked == true){
  this.objOpcionEdit.flg_estd = 1;
}else{
this.objOpcionEdit.flg_estd = 0;
}

    this.objOpcionEdit.opc_id_opcion = this.urlOpcionSelected.id_opcion;

    this.objOpcionEdit.txt_desc_opc = this.objOpcionEdit.txt_desc_opc.trim();
    this.objOpcionEdit.txt_url_opc = this.objOpcionEdit.txt_url_opc.trim();

    if (isNumber(this.objOpcionEdit.id_opcion)) {
      this.serviceOpcion.update(this.objOpcionEdit).subscribe(res => {
        this.getOpcionAll();
        this.activo = false;
        this.baja = false;
        this.getAllUrlOpcion()
        this.urlOpcionSelected = null;
        this.objOpcionEdit = new Opcion();
        this.flgEditarOpcion = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'La Opcion fue actualizado de manera correcta',
          confirmButtonText: 'Aceptar'
        })
      });
    } else {
      this.serviceOpcion.save(this.objOpcionEdit).subscribe(res => {
        this.getOpcionAll();
        this.getAllUrlOpcion();
        this.activo = false;
        this.urlOpcionSelected = null;
        this.baja = false;
        this.objOpcionEdit = new Opcion();
        this.flgNuevoOpcion = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'La Opcion fue agregado correctamente',
          confirmButtonText: 'Aceptar'
        })
      });

    }



  }

  onSort() {
    this.updateRowGroupMetaData();
}
  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};    
    if (this.ListadoOpcion) {
        for (let i = 0; i < this.ListadoOpcion.length; i++) {
            let rowData = this.ListadoOpcion[i];
            let brand = rowData.txt_desc_opces;
            if (i == 0) {
                this.rowGroupMetadata[brand] = { index: 0, size: 1 };
            }
            else {
                let previousRowData = this.ListadoOpcion[i - 1];
                let previousRowGroup = previousRowData.txt_desc_opces;
                if (brand === previousRowGroup)
                    this.rowGroupMetadata[brand].size++;
                    
                else
                    this.rowGroupMetadata[brand] = { index: i, size: 1 };
                    
            }
        }
    }

    

}

  getAllUrlOpcion(){
    this.serviceOpcion.getAllUrl().subscribe(data =>{
        this.ListadoUrlOpcion = data;
    });
  }

  SeleccionarUrl(){

    if(this.urlOpcionSelected.id_opcion == 1){
      this.objOpcionEdit.txt_url_opc = "#"
      document.getElementById("url").setAttribute("disabled", "")
    }else {
      this.objOpcionEdit.txt_url_opc = ""
      document.getElementById("url").removeAttribute("disabled")
    }

  }
  
  SeleccionarUrl1(){

    if(this.urlOpcionSelected.id_opcion == 1){
      this.objOpcionEdit.txt_url_opc = "#"
      document.getElementById("url").setAttribute("disabled", "")
    }else {
      document.getElementById("url").removeAttribute("disabled")
    }

  }

  editarOpcionCabecera(opcion : Opcion){

    this.baja = false;
    this.activo= false;
    this.blokeo = true;
    this.objOpcionSel = opcion;
    this.getOpcionIdCabecera();
    this.flgDisplayPrincipal = false;
    this.flgEditarOpcion = true;

    if (this.objOpcionSel.opcion.flg_estd != 0) {
      this.checked = true;
      this.estado = "ACTIVO"
    } else if (this.objOpcionSel.opcion.flg_estd == 0) {
      this.checked = false;
      this.estado = "BAJA"
    } else {
    }

  }

  check(){
    if(this.checked == true){
      this.estado = "ACTIVO"
    }else{
      this.estado = "BAJA"
    }
    
  }
}
