import { Injectable } from '@angular/core';
import { User } from '../models/token';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RznSocialAnexo } from '../models/rznsclanexo';
import { TipoCpe } from '../models/tipocpe';
import { RznSclSerie } from '../models/rznsclserie';
import { Usuario } from '../models/usuario';
import { RznSclUsuSerie } from '../models/rznsclususerie';
import { Banco } from '../models/banco';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RznsclserieanexoService {

  header: HttpHeaders;
  
  constructor(private http: HttpClient) { }

  loginTokenJava(url_api: string, prmData: User) {
    return this.http.post( url_api + 'token/user', prmData).toPromise();
  }

  listAnexo(url_api: string,ruc:String,token:string){
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.get<RznSocialAnexo[]>(url_api + 'rznSclAnexo/lstrznsclanexo/'+ruc,{ headers: this.header });
  }

  saveAnexos(url_api: string,prmData: RznSocialAnexo[],token:string) {
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.post<RznSocialAnexo>(url_api + 'rznSclAnexo/lstrznsclanexosaveupdate', prmData,{ headers: this.header });
  }

  listTipoCpe(url_api: string,token:string){
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.get<TipoCpe[]>(url_api + 'comun/tipoComprobante',{ headers: this.header });

  }

  listarSerieContribuyente(url_api: string,ruc:String,token:string){
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.get<RznSclSerie[]>(url_api + 'rznSclSerie/lstrznsclserie/'+ruc,{ headers: this.header });
  }


  saveSeries(url_api: string,prmData: RznSclSerie,token:string) {
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.post<RznSclSerie>(url_api + 'rznSclSerie/lstrznsclseriesaveupdate', prmData,{ headers: this.header });
  }

  deleteserie(url_api: string,id: number,token:string){
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.delete<number>(url_api + 'rznSclSerie/deleteSerie/'+id,{ headers: this.header });  
  }

  listadoUsuario(url_api: string,ruc:String,token:string){
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.get<Usuario[]>(url_api + 'usuario/listusersecurity/'+ruc,{ headers: this.header });
  
  }

  listRznSclUsuSerie(url_api: string,num_nif_emis:string,id_usuario:number,token:string){
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.get<RznSclUsuSerie[]>(url_api + 'rznsclususerie/rznsclususerielist/' + num_nif_emis + '/' + id_usuario,{ headers: this.header });
    
   }

   saveRznSclUsuSerie(url_api: string,serie:RznSclUsuSerie[],token:string){
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.post<RznSclUsuSerie>(url_api+ 'rznsclususerie/saveserieusu' ,serie,{ headers: this.header });
    
   }

   getAllBancos(url_api: string,ruc: string,token:string) {
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.get<Banco[]>(url_api+ "bancos/lista/"+ruc, { headers: this.header });
  }

  getByIdbanco(url_api: string,prmId: number,token:string) {
    this.header = new HttpHeaders({ Authorization: token });
    const url = url_api+ "bancos/banco/" + prmId;
    return this.http.get<Banco>(url, { headers: this.header });
  }

  savebanco(url_api: string,prmData: Banco,token:string) {
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.post<Banco>(url_api+ 'bancos/savebancos', prmData, { headers: this.header });
  }

  updatebanco(url_api: string,prmData: Banco,token:string): Observable<any> {
    this.header = new HttpHeaders({ Authorization: token });
    var body = prmData;
    return this.http.post<Banco>(url_api + 'bancos/bancosupdate/' + prmData.id_banco, body, { headers: this.header })
  }

  searchUbigeo(url_api: string,query,token:string) {
    this.header = new HttpHeaders({ Authorization: token });
    return this.http.get<any[]>(url_api + `guiaRemision/filterubigeo/${query}`, { headers: this.header });
}
}
