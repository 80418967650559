import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Usuario } from '../models/usuario';
import { Observable } from 'rxjs';
import { VwUsuarioRazon } from '../models/vwUsuarioRazon';
import { LogUsuario } from '../models/logUsuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private resource: string = environment.api_url_java + 'usuario/';
  private resourceLog: string = environment.api_url_java + 'log/';
  header: HttpHeaders;
  
  textToConvert: string;
  password: string;
  conversionOutput: string;


  constructor(private http: HttpClient) { 
}

  getAll() {
    return this.http.get<Usuario[]>(this.resource + 'listusuario');
  }

  getById(prmId: number) {
    const url = this.resource +"usuarioid/"+ prmId;
    return this.http.get<Usuario>(url);
  }

  save(prmData: Usuario) {
    return this.http.post<Usuario>(this.resource + 'saveUsuario', prmData);
  }
  update(prmData: Usuario): Observable<any> {
    var body = prmData;
    return this.http.post<Usuario>(this.resource+ 'updateusuario/' + prmData.id_usuario, body)
  }

  getAllusuario(codigo:string) {
    return this.http.get<Usuario[]>(this.resource + 'listusuariosfiltro/'+codigo);
  }

  updatePassword(prmData: Usuario) {
    return this.http.post<Usuario>(this.resource + 'updatepassword', prmData);
  }

  saveLog(log:LogUsuario){
    return this.http.post<Usuario>(this.resourceLog + 'save', log);
  
  }


}
