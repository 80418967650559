import { Component, OnInit } from '@angular/core';
import { Perfil } from 'src/app/models/perfil';
import { MenuOpcion } from 'src/app/models/menuOpcion';
import { TreeNode, SelectItem, MessageService } from 'primeng/api';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { OpcionmenuService } from 'src/app/services/opcionmenu.service';
import { PerfilService } from 'src/app/services/perfil.service';
import { PerfilesService } from 'src/app/services/perfiles.service';
import { ConstantMessage } from 'src/app/services/constant-MessageConfig.service';
import { ApiCatalogService } from 'src/app/services/api-catalog.service';

@Component({
  selector: 'app-mant-opcionperfil',
  templateUrl: './mant-opcionperfil.component.html',
  styleUrls: ['./mant-opcionperfil.component.css']
})
export class MantOpcionperfilComponent implements OnInit {

  
  blockedDocument: boolean = false;
  flgDisplayConfirmGrabarVarios: boolean = false;
  flgDisplayDialogOpcion: boolean = false;

  objPerfilSelected: Perfil;
  objOpcionPerfilToAdd: MenuOpcion;
  objMenuOpcionToSave: MenuOpcion = new MenuOpcion();

  lstOpcionesMenu: TreeNode[];
  lstOpcionesAsignadas: TreeNode[];
  lstPerfiles: Perfil[];
  lstOpcionesPadre: SelectItem[]
  lstEstados: SelectItem[]
  
  constructor(
    private msgCatalog: ApiMessageService,
    private opcionmenuservice: OpcionmenuService,
    private perfilservice: PerfilesService,
    private messageService: MessageService,
    private perfilesService:PerfilService,
    
    private apiCatalog: ApiCatalogService
    
    
  ) { }

  ngOnInit() {
    this.lstOpcionesMenu = [];
    this.lstOpcionesAsignadas = [];
    this.lstPerfiles = [];
    this.lstEstados = this.apiCatalog.lstEstadoActivoInactivo();

    this.getAllPerfiles();
    this.getOpcionesAll();
    this.getAllOpcionesPadre();
    
  }

  getAllPerfiles() {
    this.blockedDocument = true;
    this.perfilesService.getAll().subscribe(
      data => {
        this.lstPerfiles = data;
        if (this.lstPerfiles.length > 0) {
          this.objPerfilSelected = this.lstPerfiles[0];
          this.getOpcionesByIdPerfil();
        }
        this.blockedDocument = false;
      });

  }
  getOpcionesAll() {
    this.blockedDocument = true;
    this.opcionmenuservice.getOpcionesAll().subscribe(
      data => {
        this.lstOpcionesMenu = data;
        this.blockedDocument = false;
      });
  }

  getAllOpcionesPadre() {
    this.blockedDocument = true;
    this.opcionmenuservice.getOpcionesPadreAll().subscribe(
      data => {
        this.lstOpcionesPadre = data.map(o => {
          return {
            label: o.descripcion,
            //value: new MenuOpcion(o.id, null, o.descripcion)
            value: o.id
          }
        });
      });
  }

  getOpcionesByIdPerfil() {
    this.blockedDocument = true;
    this.opcionmenuservice.getOpcionesByIdPerfil(this.objPerfilSelected.id_perfil).subscribe(
      data => {
        this.lstOpcionesAsignadas = data;
        this.blockedDocument = false;
      });
  }

  click_btnNuevaOpcion() {
    this.flgDisplayDialogOpcion = true;
    this.objMenuOpcionToSave = new MenuOpcion();
    this.objMenuOpcionToSave.idpadre = 0;
  }

  click_btnGrabarOpcionMenu() {
    //console.log(JSON.stringify(this.objMenuOpcionToSave));
    this.blockedDocument = true;
    this.opcionmenuservice.saveOpcion(this.objMenuOpcionToSave).subscribe(
      res => {
        this.blockedDocument = false;
        //this.toastr.success('Ingreso registrado con éxito', ConstantMessage.titleSuccess, ConstantMessage.config);
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Ingreso registrado con éxito' });
        this.getOpcionesAll();
        this.getOpcionesByIdPerfil();
        this.flgDisplayDialogOpcion = false;
        this.objMenuOpcionToSave = new MenuOpcion();
      },
      err => {
        this.blockedDocument = false;
        //this.toastr.error(this.msgCatalog.msgCuerpo(err.error.message), ConstantMessage.titleError, ConstantMessage.config);
       this.messageService.add({ severity: 'error', summary: 'ups', detail: 'Hubo un error' });
        this.getOpcionesAll();
        this.getOpcionesByIdPerfil();
        this.flgDisplayDialogOpcion = false;
        this.objMenuOpcionToSave = new MenuOpcion();
      });
  }

  click_btnEditarOpcion(prmIdOpcion: any) {
    //alert(prmIdOpcion);
    this.objMenuOpcionToSave = new MenuOpcion();
    this.blockedDocument = true;
    this.opcionmenuservice.getbyId(prmIdOpcion).subscribe(
      res => {
        this.objMenuOpcionToSave = res;
        this.blockedDocument = false;
        this.flgDisplayDialogOpcion = true;
      },
      err => {
        this.blockedDocument = false;
       // this.toastr.error(this.msgCatalog.msgCuerpo(err.error.message), ConstantMessage.titleError, ConstantMessage.config);
       this.messageService.add({ severity: 'error', summary: 'ups', detail: 'Hubo un error' });
      });
  }

  click_btnExpandirGeneral() {
    this.lstOpcionesMenu.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  click_btnContraerGeneral() {
    this.lstOpcionesMenu.forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  change_perfil(event: any) {
    this.getOpcionesByIdPerfil();
  }

  onDrop_lstOpcionesAsignadas(event) {
    //event.accept();
    let objNodo = event.dragNode;
    objNodo.data.idperfil = this.objPerfilSelected.id_perfil;
    this.objOpcionPerfilToAdd = objNodo.data;

    if (objNodo.children != null) {
      this.flgDisplayConfirmGrabarVarios = !this.flgDisplayConfirmGrabarVarios;
    }
    else {
      this.saveAddOpcion();
    }

  }

  /**
   * Para desasignar un item (s) al perfin deseado
   * @param event 
   */
  onDrop_lstOpcionesMenu(event) {
    //event.accept();
    this.blockedDocument = true;
    let objNodo = event.dragNode;

    if(objNodo.data==null){
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Proceso culminado con éxito' });
      return 0;
    }

    objNodo.data.idperfil = this.objPerfilSelected.id_perfil;

    this.perfilservice.delOpcion(objNodo.data).subscribe(
      res => {
        this.blockedDocument = false;
        //this.toastr.success('Proceso culminado con éxito', ConstantMessage.titleSuccess, ConstantMessage.config);
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Ingreso registrado con éxito' });
        this.getOpcionesAll();
        this.getOpcionesByIdPerfil();
      },
      err => {
        this.blockedDocument = false;
        //this.toastr.error(this.msgCatalog.msgCuerpo(err.error.message), ConstantMessage.titleError, ConstantMessage.config);
        this.messageService.add({ severity: 'error', summary: 'ups', detail: 'Hubo un error' });
        this.getOpcionesAll();
        this.getOpcionesByIdPerfil();
      });

  }

  saveAddOpcion() {
    this.blockedDocument = true;
    this.perfilservice.addOpcion(this.objOpcionPerfilToAdd).subscribe(
      res => {
        this.blockedDocument = false;
        //this.toastr.success('Ingreso registrado con éxito', ConstantMessage.titleSuccess, ConstantMessage.config);
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Ingreso registrado con éxito' });
        this.getOpcionesAll();
        this.getOpcionesByIdPerfil();
        this.flgDisplayConfirmGrabarVarios = false;
      },
      err => {
        this.blockedDocument = false;
      //  this.toastr.error(this.msgCatalog.msgCuerpo(err.error.message), ConstantMessage.titleError, ConstantMessage.config);
      this.messageService.add({ severity: 'error', summary: 'ups', detail: 'Hubo un error' });
        this.getOpcionesAll();
        this.getOpcionesByIdPerfil();
        this.flgDisplayConfirmGrabarVarios = false;
      });
  }


}
