import { Contribuyente } from './contribuyente';
import { Usuario } from './usuario';
import { Perfil } from './perfil';
import { Proveedor } from './proveedor';

export class RznSclUsuario {

    id:number;
    id_usuario:number;
    id_rzn_scl:number;
    flg_estd:number;
    flg_default:number;
    id_usu_crea:number;
    fch_usu_crea:Date;
    id_usu_modi:number;
    fch_usu_modi:Date;
    id_perfil:number;
    contribuyente:Contribuyente;
    usuario:Usuario;
    perfil:Perfil;
    proveedor:Proveedor;

}