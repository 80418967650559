import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario';

import { Message, MessageService } from 'primeng/api';
import Swal from 'sweetalert2'
import { LoginService } from 'src/app/services/login.service';
import { VwUsuarioRazon } from 'src/app/models/vwUsuarioRazon';
import { ConstantMessage } from 'src/app/services/constant-MessageConfig.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-mant-new-contrasena',
  templateUrl: './mant-new-contrasena.component.html',
  styleUrls: ['./mant-new-contrasena.component.css']
})
export class MantNewContrasenaComponent implements OnInit {

  objUsuarioPassword : Usuario = new Usuario();
  blockedDocument: boolean = false;
  msgs: Message[] = [];
  objvWusuarioRazon: VwUsuarioRazon = new VwUsuarioRazon();

  show: boolean;
  ojo;
  show1: boolean;
  ojo1;
  show2: boolean;
  ojo2;
  constructor(private serviceseguridad: UsuarioService,
    private messageService: MessageService,
    private loginservice: LoginService,) { }

  ngOnInit() {
    this.objvWusuarioRazon = this.loginservice.getUser();
    this.objUsuarioPassword = new Usuario();
    this.objUsuarioPassword.cod_usuario = this.objvWusuarioRazon.cod_usuario;
    this.ojo = "fa fa-eye"
    this.show = false
    this.ojo1 = "fa fa-eye"
    this.show1 = false
    this.ojo2 = "fa fa-eye"
    this.show2 = false
  }

  Click_updatePassword(){    
    if(this.objUsuarioPassword.cod_usuario == null || this.objUsuarioPassword.cod_usuario == ""){
    
      this.msgs.push({severity:'error', summary:'Error', detail:'Debe Ingresar Usuario'});
      return false;
    }

    
    if(this.objUsuarioPassword.txt_pass_actual == null || this.objUsuarioPassword.txt_pass_actual == ""){
  
      this.msgs.push({severity:'error', summary:'Error', detail:'Debe Ingresar Contraseña Actual'});
      return false;
    }

    
    if(this.objUsuarioPassword.txt_pass_usuario == null || this.objUsuarioPassword.txt_pass_usuario == ""){
      
      this.msgs.push({severity:'error', summary:'Error', detail:'Debe Ingresar Nueva contraseña'});
      return false;
    }

    
    if(this.objUsuarioPassword.txt_pass_usuario_confirm == null || this.objUsuarioPassword.txt_pass_usuario_confirm == ""){
      
      this.msgs.push({severity:'error', summary:'Error', detail:'Debe Ingresar Confirmar nueva contraseña'});
      return false;
    }

    
    this.blockedDocument = true

    var usuario = new Usuario();
    usuario.cod_usuario = this.objUsuarioPassword.cod_usuario;
    usuario.txt_pass_usuario = this.objUsuarioPassword.txt_pass_usuario;
    usuario.txt_pass_actual = this.objUsuarioPassword.txt_pass_actual;

    if(this.objUsuarioPassword.txt_pass_usuario === this.objUsuarioPassword.txt_pass_usuario_confirm){


    this.serviceseguridad.updatePassword(usuario).subscribe(data =>{
        this.blockedDocument = false

        if(data.txt_error != null){
          this.msgs = [];
          this.msgs.push({severity:'warn', summary:'Alerta', detail: data.txt_error});
        }else{
          Swal.fire({
            type: 'success',
            title: 'Contraseña Actualizada',
            text: this.objUsuarioPassword.cod_usuario,
          })
          this.objUsuarioPassword = new Usuario();
        }
      
    });
    
  }else{
    this.msgs = [];
    this.msgs.push({severity:'warn', summary:'Alerta', detail:"La nueva Contraseña debe ser igual al Confirmar Contraseña"});
    this.blockedDocument = false
  }

}

apassword() {
  this.show = !this.show;
  if (this.show == true) {
    this.ojo = "fa fa-eye-slash icon"
  } else if (this.show == false) {
    this.ojo = "fa fa-eye"
  }
}
apassword1() {
  this.show1= !this.show1;
  if (this.show1 == true) {
    this.ojo1 = "fa fa-eye-slash icon"
  } else if (this.show1 == false) {
    this.ojo1 = "fa fa-eye"
  }
}
apassword2() {
  this.show2 = !this.show2;
  if (this.show2 == true) {
    this.ojo2 = "fa fa-eye-slash icon"
  } else if (this.show2 == false) {
    this.ojo2 = "fa fa-eye"
  }
}


}
