import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuOpcion } from '../models/menuOpcion';
import { TreeOpcionMenu } from '../models/treeOpcionMenu';
import { Menu } from '../models/menu';

@Injectable({
  providedIn: 'root'
})
export class OpcionmenuService {
  private resourcemenu: string = environment.api_url_java + 'menu/';
  password: string;
  header: HttpHeaders;

  constructor(private http: HttpClient,) {
    }

   saveOpcion(prmOpcion: MenuOpcion) {
    return this.http.post<MenuOpcion>(this.resourcemenu + 'saveopcion/', prmOpcion);
  }

  getbyId(prmId: number) {
    return this.http.get<MenuOpcion>(this.resourcemenu + 'get/' + prmId);
  }

  getOpcionesAll() {
    return this.http.get<TreeOpcionMenu[]>(this.resourcemenu);
  }

  getOpcionesByIdPerfil(pIdPerfil: number) {
    return this.http.get<TreeOpcionMenu[]>(this.resourcemenu + pIdPerfil);
  }

  getOpcionesPadreAll() {
    return this.http.get<MenuOpcion[]>(this.resourcemenu + "padres");
  }

  menuAngular(codigo :string,id_rzn_scl:number){
    return this.http.get(this.resourcemenu + 'menusA/'+codigo + '/'+id_rzn_scl);
    //Menu[]
  }
}
