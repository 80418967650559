import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Perfil } from '../models/perfil';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  private resource: string = environment.api_url_java + 'perfil/';
  header: HttpHeaders;
  
  textToConvert: string;
  password: string;
  conversionOutput: string;

  constructor(private http: HttpClient) { 
  }

  getAll() {
    return this.http.get<Perfil[]>(this.resource + 'listperfil');
  }

  getById(prmId: number) {
    const url = this.resource +"perfilid/"+ prmId;
    return this.http.get<Perfil>(url);
  }

  save(prmData: Perfil) {
    return this.http.post<Perfil>(this.resource + 'saveperfil', prmData);
  }
  update(prmData: Perfil): Observable<any> {
    var body = prmData;
    return this.http.post<Perfil>(this.resource+ 'perfilupdate/' + prmData.id_perfil, body)
  }
}
