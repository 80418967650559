import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ArchivoPrmConsultaRUC } from '../models/archivoPrmCosultaRUC';
import { ArchivoResponseConsultaRUC } from '../models/archivoResponseConsutaRUC';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiArchivoService {

  private urlWebServiceMiFactSUNAT: string = environment.urlWsMiFactSUNAT;

  constructor(private http: HttpClient) { }

  getConsultaRUC(prmData: ArchivoPrmConsultaRUC): Observable<ArchivoResponseConsultaRUC> {
    return this.http.post<ArchivoResponseConsultaRUC>(this.urlWebServiceMiFactSUNAT, prmData);
  }
}
