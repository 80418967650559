import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ParametroProveedor } from '../models/parametroProveedor';
import { Proveedor } from '../models/proveedor';

@Injectable({
  providedIn: 'root'
})
export class ParametroProveedorService {

    private path: string = `${environment.api_url_java}parametroproveedor`;

    constructor( private http: HttpClient ) { }


    getFilterProveedores( filter : string ) {
      return this.http.get<Proveedor[]>(`${this.path}/filter/${filter}`);
    }

    saveParametroProveedor(parametroProveedor : ParametroProveedor) {
      return this.http.post<ParametroProveedor>(this.path, parametroProveedor)
    }

    updateParametroProveedor(parametroProveedor : ParametroProveedor) {
      return this.http.put<ParametroProveedor>(this.path, parametroProveedor)
    }

    findParametroProveedor( id : number) {
      return this.http.get<ParametroProveedor>(`${this.path}/${id}`);
    }

    findParametrosPorProveedor(idProveedor : number) {
      return this.http.get<ParametroProveedor[]>(`${this.path}/proveedor/${idProveedor}`);
    }

    getParametrosProveedorPorRuc(ruc : string) {
      return this.http.get<ParametroProveedor[]>(`${this.path}/ruc/${ruc}`);
    }

    getParametrosProvedor() {
      return this.http.get<ParametroProveedor[]>(this.path);
    }

}
