import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Planes } from '../models/planes';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PlanesService {
  private resource: string = environment.api_url_java + 'planes/';
  header: HttpHeaders;
  
  textToConvert: string;
  password: string;
  conversionOutput: string;

  constructor(private http: HttpClient) { 
    }
  

  getAll() {
    return this.http.get<Planes[]>(this.resource + 'listplanes');
  }

  getById(prmId: number) {
    const url = this.resource +"plaensid/"+ prmId;
    return this.http.get<Planes>(url);
  }

  save(prmData: Planes) {
    return this.http.post<Planes>(this.resource + 'saveplanes', prmData);
  }
  update(prmData: Planes): Observable<any> {
    var body = prmData;
    return this.http.post<Planes>(this.resource+ 'planesupdate/' + prmData.id_planes, body)
  }

  getAllactivos() {
    return this.http.get<Planes[]>(this.resource + 'listplanesActivos');
  }

  searchUbigeo(query) {
    return this.http.get<any[]>(this.resource +  `filterubigeo/${query}`);
}

}
