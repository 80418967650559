import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RznSclUsuario } from '../models/rznsclusuario';
import { Observable } from 'rxjs';
import { Contribuyente } from '../models/contribuyente';
import { Usuario } from '../models/usuario';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class RznsclusuarioService {
  
  private resource: string = environment.api_url_java + 'rznsclusuario/';
  header: HttpHeaders;

  
  textToConvert: string;
  password: string;
  conversionOutput: string;

  constructor(private http: HttpClient) { 
}

  getAll() {
    return this.http.get<RznSclUsuario[]>(this.resource + 'listrznsclusu');
  }

  getById(prmId: number) {
    const url = this.resource +"rznsclusuid/"+ prmId;
    return this.http.get<RznSclUsuario>(url);
  }

  save(prmData: RznSclUsuario) {
    return this.http.post<RznSclUsuario>(this.resource + 'saverznsclusu', prmData);
  }
  update(prmData: RznSclUsuario): Observable<any> {
    var body = prmData;
    return this.http.post<RznSclUsuario>(this.resource+ 'rznsclupdate/' + prmData.id, body)
  }

  getAllempresaFiltro(desc:string) {
    return this.http.get<Contribuyente[]>(this.resource + 'liscontribuyentefiltro/'+ desc);
  }

  getAllusuarioFiltro(desc:string) {
    return this.http.get<Usuario[]>(this.resource + 'listusuariofiltro/'+ desc);
  }

  getAllfiltro(id_rzn_scl:number,id_usuario:number) {
    return this.http.get<RznSclUsuario[]>(this.resource + 'listrznfiltro/'+id_rzn_scl+"/"+id_usuario);
  }

}
