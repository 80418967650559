import { Planes } from './planes';
import { SeguridadParametro } from './seguridadparametro';
import { Proveedor } from './proveedor';

export class Contribuyente {

    id_seguridad: number;
    num_nif_emis: string;
    razon_social: string;
    direccion_fiscal: string;
    id_plan: number;
    num_nif_proveedor: string;
    flg_bloqueo_pago: number;
    fec_bloqueo_pago: string;
    flg_estado: number;
    correo_contacto: string;
    fono_contacto: string;
    cant_doc_max: string;
    planes: Planes;
    proveedor: Proveedor;
    nroDocumentoRazonSocial: string;
    nom_cmrcl: string;
    ubigeo: string;
    cta_detraccion: string;
    fono_pdf_cpe: string;
    correo_pdf_cpe: string;
    web_pdf_cpe: string;
    fec_baja: string;
    fec_ingreso: string;
    archivoBase64: string;
    imagenS3: string
    tamañoImagenS3: string
    tipoImagenS3: string
    ls_url_images_s3_accessKey: string
    ls_url_images_s3_secretKey: string
    flg_ose: number = 0;
    flg_produccion: number = 1;
    observaciones:string = ""
    glosa_html:string = ""
    flg_posicion_glosa : number;
    numero_mtc:string = "";
    num_nif_facturacion:string;
    razon_social_facturacion:string;
    num_nif_contacto:string="";
    nombre_contacto:string;
    tipo_contacto:string;
    tipo_baja:string;

}