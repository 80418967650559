export class Opcion {

    id_opcion:number;
    opc_id_opcion:number;
    txt_desc_opc:string;
    txt_url_opc:string;
    flg_estd:number;
    num_orden:number;
    num_ordenes:number;
    txt_desc_opces:string;
    icono:string;
    opcion:Opcion;
}