import { Injectable } from '@angular/core';
import { PrimeNGConfig, SelectItem } from 'primeng/api';
import { Moneda } from '../models/moneda';
import { PerfilUsuario } from '../models/perfilPermiso';


@Injectable({
  providedIn: 'root'
})
export class ApiCatalogService {

  constructor( private config: PrimeNGConfig) { }

  public calendarEsConfiguration(): any {
    let conf: any;
    conf = {
      firstDayOfWeek: 1,
      dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar'
    }
    return conf;
  }

  public setTranslation() {
    return this.config.setTranslation({
      firstDayOfWeek: 1,
      dayNames: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      monthNamesShort: [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ],
      today: "Hoy",
      clear: "Borrar",
      emptyMessage: "Sin resultado",
      emptyFilterMessage: "Sin resultado",
    });
  }


  public lstEstadoActivoInactivo(): SelectItem[] {
    let lstRetorno: SelectItem[];
    lstRetorno = [
      { label: 'Seleccione', value: null },
      /*{ label: 'Activo', value: { id: '1', name: 'Activo', code: '1' } },
      { label: 'Inactivo', value: { id: '0', name: 'Inactivo', code: '0' } }*/
      { label: 'Activo', value: 1 },
      { label: 'Inactivo', value: 0 }
    ];
    return lstRetorno;
  }

  
  public lstEstadoObligatorio(): SelectItem[] {
    let lstRetorno: SelectItem[];
    lstRetorno = [
      { label: 'Seleccione', value: null },
      /*{ label: 'Activo', value: { id: '1', name: 'Activo', code: '1' } },
      { label: 'Inactivo', value: { id: '0', name: 'Inactivo', code: '0' } }*/
      { label: 'Si', value: 1 },
      { label: 'NO', value: 0 }
    ];
    return lstRetorno;
  }

  public lstTipoGuiaRemision(): SelectItem[] {
    let lstRetorno: SelectItem[];
    lstRetorno = [
      { label: 'Seleccione', value: null },
      { label: 'Guía de remisión remitente', value: { id: '09', name: 'Guía de remisión remitente', code: '09' } },
      { label: 'Guía de remisión transportista', value: { id: '56', name: 'Guía de remisión transportista', code: '56' } }
    ];
    return lstRetorno;
  }






  public lstDetraccionPorcentaje(): SelectItem[] {
    let lstRetorno: SelectItem[];
    lstRetorno = [
      { label: 'Seleccione', value: null },
      { label: '10%', value: { id: '2', name: '2' } },
      { label: '15%', value: { id: '4', name: '4' } },
      { label: '4%', value: { id: '3', name: '3' } },
      { label: '2%', value: { id: '5', name: '5' } },
      { label: '1.5%', value: { id: '7', name: '7' } }
    ];
    return lstRetorno;
  }

  public lstDetaccionCodigoBien(): SelectItem[] {
    let lstRetorno: SelectItem[];
    lstRetorno = [
      { label: 'Seleccione', value: null },
      { label: '001-Azucar', value: { id: '001', name: '001-Azucar', code: '001' } },
      { label: '003-Alcohol', value: { id: '003', name: '003-Alcohol', code: '003' } },
      { label: '005-Maiz amarillo', value: { id: '005', name: '005-Maiz amarillo', code: '005' } },
      { label: '006-Algodon', value: { id: '006', name: '006-Algodon', code: '006' } },
      { label: '007-Caña de azucar', value: { id: '007', name: '007-Caña de azucar', code: '007' } },
      { label: '008-Madera', value: { id: '008', name: '008-Madera', code: '008' } },
      { label: '009-Arena y piedra', value: { id: '009', name: '009-Arena y piedra', code: '009' } },
      { label: '010-Residuos subproducto, desechos, recortes', value: { id: '010', name: '010-Residuos subproducto, desechos, recortes', code: '010' } },
      { label: '011-Bienes de inciso A-Azucar', value: { id: '011', name: '011-Bienes de inciso A-Azucar', code: '011' } },
      { label: '012-Intermediacion laboral y terecerzacion', value: { id: '012', name: '012-Intermediacion laboral y terecerzacion', code: '012' } },
      { label: '013-Animales vivo', value: { id: '013', name: '013-Animales vivo', code: '013' } },
      { label: '014-Carne y despojos', value: { id: '014', name: '014-Carne y despojos', code: '014' } },
      { label: '015-Abonos,cueros y pieles animales', value: { id: '015', name: '015-Abonos,cueros y pieles animales', code: '015' } },
      { label: '016-Aceite y pescado', value: { id: '016', name: '016-Aceite y pescado', code: '016' } },
      { label: '019-Arrendamiento de bienes muebles', value: { id: '017', name: '019-Arrendamiento de bienes muebles', code: '017' } },
      { label: '020-Mantenimiento y reparacion de bienes', value: { id: '020', name: '020-Mantenimiento y reparacion de bienes', code: '020' } },
      { label: '021-Movimiento de carga', value: { id: '021', name: '021-Movimiento de carga', code: '021' } },
      { label: '023-Leche', value: { id: '023', name: '023-Leche', code: '023' } },
      { label: '024-Comision mercantil', value: { id: '024', name: '024-Comision mercantil', code: '024' } },
      { label: '025-Fabricacion de bienes por encargo', value: { id: '025', name: '025-Fabricacion de bienes por encargo', code: '025' } },
      { label: '027- Servicio de transporte de bienes por via terrestre', value: { id: '027', name: '027- Servicio de transporte de bienes por via terrestre', code: '027' } },
      { label: '035- Bienes exonerados de IGV', value: { id: '035', name: '035- Bienes exonerados de IGV', code: '035' } },
      { label: '030- Contratos de construcción', value: { id: '030', name: '030- Contratos de construcción', code: '030' } },
      { label: '037-Otros servicios grabados con el I.G.V.', value: { id: '037', name: '037-Otros servicios grabados con el I.G.V.', code: '037' } }
    ];
    return lstRetorno;
  }

  public lstTipoMonedas(): Moneda[] {
    let lstRetorno: Moneda[];
    lstRetorno = [
        { label: "Soles", value: "PEN" },
        { label: "Dólares", value: "USD" },
    ];
    return lstRetorno;
}


public lstPerfilUsuario(): PerfilUsuario[] {
  let lstRetorno: PerfilUsuario[];
  lstRetorno = [
      { id_pefil: 1, txt_perfil: "ADMINISTRADOR" },
      { id_pefil: 2, txt_perfil: "CONTADOR" },
      { id_pefil: 3, txt_perfil: "ROOT" },
      { id_pefil: 4, txt_perfil: "USUARIO" },
      { id_pefil: 5, txt_perfil: "SOPORTE DISTRIBUIDOR" },
      { id_pefil: 6, txt_perfil: "SOPORTE MIFACT" },
  ];
  return lstRetorno;
}

}
