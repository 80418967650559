import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UsuarioRazonSocial } from '../models/usuarioRazonSocial';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioRazonSocialService {

  private resource: string = environment.api_url_java + 'seguridad/';
  header: HttpHeaders;
  
  textToConvert: string;
  password: string;
  conversionOutput: string;
  constructor(private http: HttpClient) {
   }

   getAll() {
    return this.http.get<UsuarioRazonSocial[]>(this.resource + 'listUsuario_razon_social');
  }

  getById(prmId: number) {
    const url = this.resource +"Usuario_razon_socialid/"+ prmId;
    return this.http.get<UsuarioRazonSocial>(url);
  }

  save(prmData: UsuarioRazonSocial) {
    return this.http.post<UsuarioRazonSocial>(this.resource + 'saveUsuario_razon_social', prmData);
  }
  update(prmData: UsuarioRazonSocial): Observable<any> {
    var body = prmData;
    return this.http.post<UsuarioRazonSocial>(this.resource+ 'Usuario_razon_socialupdate/' + prmData.id_usuario_razon_social, body)
  }

  getallusuario(codigo:string) {
    return this.http.get<UsuarioRazonSocial[]>(this.resource + 'validacion/save_new_usuario/'+codigo);
  }
}
