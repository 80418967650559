export class Usuario {
    id_usuario:number;
    cod_usuario:string;
    txt_pass_usuario:string;    
    txt_ape_pat_usuario:string;
    txt_ape_mat_usuario:string;
    txt_nom_cmp_usuario:string;
    txt_desc_email:string;
    fec_expira:string;
    fec_crea:string;
    flg_estd:string;
    id_usu_crea:number;
    fch_usu_crea:string;
    id_usu_modi:number;
    fch_usu_modi:string;
    num_int_bloq:number;
    flg_bloq:string;
    flg_bloqueo_pago:string;
    pin : string;
    txt_pass_actual:string;
    txt_error:string;
    txt_pass_usuario_confirm:string;

    cod_impre_usu_2 : string;
    cod_impre_usu : string;
    cod_form_impr : string;
    flg_administradorlocal : number;


}