export class Proveedor {
    id_proveedores:number;
    nom_rzn_social_prov:string;
    txt_dmcl_fisc_prov:string;
    num_ruc_prov:string;
    txt_mail_prov:string;
    num_tfl_prov:string;
    flg_estado:number;
    flg_estadoVisual: boolean;
    nroDocumentoRazonSocial:string;
}