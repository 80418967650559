import { Component, OnInit } from '@angular/core';
import { Planes } from 'src/app/models/planes';
import { MessageService } from 'primeng/api';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import { PlanesService } from 'src/app/services/planes.service';
import Swal from 'sweetalert2'
import { isNumber } from 'is-what';

@Component({
  selector: 'app-mant-planes',
  templateUrl: './mant-planes.component.html',
  styleUrls: ['./mant-planes.component.css']
})
export class MantPlanesComponent implements OnInit {

  Listadoplanes: Planes[];

  objPlanesEdit: Planes = new Planes();
  objPlanesSel: Planes = new Planes();

  activo: boolean;
  baja: boolean;

  flgDisplayPrincipal: boolean;
  flgnuevoeditaplanes: boolean;
  loading: boolean;
  flgeditarplan: boolean;

  checked: boolean;
  estado:string;
  arrGlobalFilters = ['descripcion_plan'];

  constructor(
    private messageService: MessageService,
    private serviceplanes: PlanesService,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
  ) { }

  ngOnInit() {
    this.flgDisplayPrincipal = true;
    this.blokeo = false;
    this.loading = true;
    this.getPlanesAll();
  }

  getPlanesAll() {
    this.serviceplanes.getAll().subscribe(data => {
      this.Listadoplanes = data;
      this.loading = false;;
    })
  }


  bntnuevo() {
    this.flgDisplayPrincipal = false
    this.flgnuevoeditaplanes = true
    this.checked = true;
    this.estado = "ACTIVO"
  }
  btncancelar() {
    this.flgDisplayPrincipal = true
    this.flgnuevoeditaplanes = false
    this.flgeditarplan = false
    this.objPlanesEdit = new Planes();

  }

  click() {
    if (this.activo != true) {
      this.objPlanesEdit.estado = 0;
    } else {
      this.objPlanesEdit.estado = 1;
      this.baja = false;
    }
  }

  click1() {
    if (this.baja != true) {
      this.objPlanesEdit.estado = 1;

    } else {
      this.objPlanesEdit.estado = 0;
      this.activo = false;
    }
  }

  Guardarplanes() {
    this.blokeo = true;

    if (this.objPlanesEdit.descripcion_plan == null || this.objPlanesEdit.descripcion_plan.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Descripción Detallada del Plan', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }


    if (this.objPlanesEdit.descripcion_corta == null || this.objPlanesEdit.descripcion_corta.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Descripción Corta del Plan', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    if (this.objPlanesEdit.precio == null || this.objPlanesEdit.precio == 0) {
      this.messageService.add({ severity: 'error', summary: 'Precio', detail: 'Debe ser mayor a 0' });
      this.blokeo = false;
      return false;
    }

    if (this.objPlanesEdit.cantidad_max_cpe == null || this.objPlanesEdit.cantidad_max_cpe == 0) {
      this.messageService.add({ severity: 'error', summary: 'Cant. Maxíma de CPE', detail: 'Debe ser mayor a 0' });
      this.blokeo = false;
      return false;
    }

    this.objPlanesEdit.descripcion_plan = this.objPlanesEdit.descripcion_plan.trim() 
    this.objPlanesEdit.descripcion_corta = this.objPlanesEdit.descripcion_corta.trim() 

    if(this.checked == true){
        this.objPlanesEdit.estado = 1;
    }else{
      this.objPlanesEdit.estado = 0;
    }

    if (isNumber(this.objPlanesEdit.id_planes)) {
      this.serviceplanes.update(this.objPlanesEdit).subscribe(res => {
        this.getPlanesAll();
        this.activo = false;
        this.baja = false;

        this.objPlanesEdit = new Planes();
        this.flgeditarplan = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El plan fue actualizado de manera correcta',
          confirmButtonText: 'Aceptar'
        })
      });
    } else {
      this.serviceplanes.save(this.objPlanesEdit).subscribe(res => {
        this.getPlanesAll();
        this.activo = false;
        this.baja = false;
        this.objPlanesEdit = new Planes();
        this.flgnuevoeditaplanes = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El plan fue agregado correctamente',
          confirmButtonText: 'Aceptar'
        })
      });

    }
  }

  getPlanesId() {
    this.serviceplanes.getById(this.objPlanesSel.id_planes).subscribe(
      data => {
        this.objPlanesEdit = { ...data };
        this.blokeo = false
      });
  }


  editarplan(planes: Planes) {

    this.blokeo = true;
    this.objPlanesSel = planes;
    this.getPlanesId();
    this.flgDisplayPrincipal = false;
    this.flgeditarplan = true;
    if (this.objPlanesSel.estado != 0) {
      this.checked = true;
      this.estado = "ACTIVO"
    } else if (this.objPlanesSel.estado == 0) {
      this.checked = false;
      this.estado = "BAJA"
    } 
  }

  check(){
    if(this.checked == true){
      this.estado = "ACTIVO"
    }else{
      this.estado = "BAJA"
    }
    
  }

  
}
