export class Parametro {
    id_parametro:number;
    desc_abreviado:string;
    desc_parametro:string;
    flg_estado:boolean;
    flg_obligatorio:number;
    id_seguridad_parametro:number;
    num_nif_emis:string;
    desc_valor:string;
    parametro : Parametro;

    desc:string;
    num_nif_contribuyente:string;

    flg_ocultar : number;
}