import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActualizacionApp } from '../models/actualizacionapp';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActualizacionAppService {

  private resource: string = environment.api_url_java + 'actualizacion/';
  
  constructor(private http: HttpClient) { 
  }

  getAll() {
    return this.http.get<ActualizacionApp[]>(this.resource + 'listversion');
  }

  getById(prmId: number) {
    const url = this.resource +"versionid/"+ prmId;
    return this.http.get<ActualizacionApp>(url);
  }

  save(prmData: ActualizacionApp) {
    return this.http.post<ActualizacionApp>(this.resource + 'saveversion', prmData);
  }
  update(prmData: ActualizacionApp): Observable<any> {
    var body = prmData;
    return this.http.post<ActualizacionApp>(this.resource+ 'versionupdate/' + prmData.id, body)
  }
}
