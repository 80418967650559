import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ApiMessageService {

  private objMensaje: { severity: string; summary: string; detail: string };
  private objMensajeExito: any;

  constructor() { }

  public msgSuccessInsert(): any {
    this.objMensajeExito = {
      severity: 'success',
      summary: 'Registrar',
      detail: 'Proceso culminado satisfactoriamente'
    };

    return this.objMensajeExito;
  }

  public msgSuccessUpdate(): any {
    this.objMensajeExito = {
      severity: 'success',
      summary: 'Actualizar',
      detail: 'Registro actualizado satisfactoriamente'
    };

    return this.objMensajeExito;
  }

  public msgSuccessUpdateStateEnProceso(): any {
    this.objMensajeExito = {
    severity: 'warn',
    summary: 'Actualizar',
    detail: 'Pedido En Proceso!!'
    };
    
    return this.objMensajeExito;
    }
    public msgSuccessUpdateStateDespachado(): any {
    this.objMensajeExito = {
    severity: 'success',
    summary: 'Actualizar',
    detail: 'Pedido despachado!!'
    };
    
    return this.objMensajeExito;
    }
    

  public msgErrorNotSelect(): any {
    this.objMensajeExito = {
      severity: 'warn',
      summary: 'Sin selección',
      detail: 'Debe seleccionar un registro para continuar con el proceso'
    };

    return this.objMensajeExito;
  }

  public lstEstadoActivoInactivo(): SelectItem[] {
    let types: SelectItem[];
    types = [
      { label: 'Activo', value: true, icon: 'fa fa-check-circle' },
      { label: 'Inactivo', value: false, icon: 'fa fa-times-circle' }
    ];
    return types;
  }

   public msgCuerpo(strMensaje: string): any {
    return '<span class= "now-ui-icons ui-1_bell-53"></span><b>Alerta!</b > - ' + strMensaje;
  }
  public rucexist(): any {
    this.objMensajeExito = {
      severity: 'warn',
      summary: 'El Ruc',
      detail: 'Ya se encuentra registrado'
    };

    return this.objMensajeExito;
  }

  public menosa2(): any {
    this.objMensajeExito = {
      severity: 'warn',
      summary: 'Parametro ',
      detail: 'Debe ingresar minimo 2 caracteres'
    };

    return this.objMensajeExito;
  }
  

}
