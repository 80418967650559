import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { OpcionmenuService } from 'src/app/services/opcionmenu.service';
import { BlockUIModule } from 'primeng/blockui';
import { MenuItem } from 'primeng/api';
import { Menu } from 'src/app/models/menu';
import { VwUsuarioRazon } from 'src/app/models/vwUsuarioRazon';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  visibleSidebar1;
  items: MenuItem[];

  menuItems: any[];

  SubmenusItems: any[];
  menuItemsPrimerNivel: any[];
  menu: Menu[];
  lstOpciones: Menu[];
  ObjmenusSel: Menu = new Menu();
  ObjmenusEdit: Menu = new Menu();
  razonusuario:VwUsuarioRazon;
  objvWusuarioRazon:VwUsuarioRazon = new VwUsuarioRazon();
  private event: MouseEvent;
  private clientX = 0;
  password: string;
  private clientY = 0;
  ruta:string;

  constructor(public servicelogin : LoginService,
    private menuservice:OpcionmenuService,
    private loginservice:LoginService,
    private blokeo: BlockUIModule,
    private router: Router,) { }

  ngOnInit() {
    this.blokeo = false
    this.objvWusuarioRazon = this.loginservice.getUser();
    this.cabecera();
    this.ruta = window.location.pathname
   
  }

  
cabecera(){
  this.menuservice.menuAngular( this.objvWusuarioRazon.cod_usuario, this.objvWusuarioRazon.id_rzn_scl).subscribe(data => {
    
    this.lstOpciones = JSON.parse(JSON.stringify(data));


    var listaCan = this.lstOpciones;
    listaCan = listaCan.filter(a => a.txt_URL_OPC != "#")
    
    this.password ="15646^&amp;%$3(),>2134bgGz*-+e7hds";

    localStorage.setItem("menu",CryptoJS.AES.encrypt(JSON.stringify(listaCan), this.password.trim()).toString())

    this.lstOpciones = _.orderBy(this.lstOpciones, ['num_ORDEN'], ['asc']);
    this.menuItemsPrimerNivel = this.lstOpciones.filter(menuItem => menuItem.opc_ID_OPCION == '1');
  
    let i = 0;
    this.menuItemsPrimerNivel.forEach(element => {
        let objItem: any;
        objItem = {
            label: element.txt_DESC_OPC,
            icon: element.url_icon,
            //routerLink: [element.TXT_URL_OPC]
        };
        if (i == 0) {
          
            this.items = [
                objItem
            ];
        } else {
            this.items[i] = objItem;
        }
        i++;
  
    });
  
    /** Agregando los hijos a los nodos raíz */
    this.lstOpciones.forEach(itemOpciones => {
        let lstHijos: Menu[] = this.lstOpciones.filter(item => item.opc_ID_OPCION == itemOpciones.id_OPCION && item.txt_URL_OPC != '#');
        let indexOpcion = this.items.findIndex(i => i.label == itemOpciones.txt_DESC_OPC);
        this.addChildToMenu(indexOpcion, lstHijos, this.items);
    });
  
  })
}

addChildToMenu(indexNivel: number, lstChilds: Menu[], lstTmpItems: MenuItem[]) {

var arrHijos: any[];

lstChilds.forEach(elementHijos => {
  let objHijo: any;
  arrHijos=[];

  objHijo = {
      label: elementHijos.txt_DESC_OPC,
      routerLink: [elementHijos.txt_URL_OPC],
  };


  if (lstTmpItems[indexNivel].items == undefined) {
      lstTmpItems[indexNivel].items = [objHijo];
  } else {
      arrHijos=lstTmpItems[indexNivel].items
      arrHijos.push(objHijo);
      lstTmpItems[indexNivel].items = arrHijos;
  }
  
});
}


 ocurrioUnEvento(event: MouseEvent): void {
  this.event = event;
}

 coordenadas(event: MouseEvent):void {
  this.clientX = event.clientX;
  this.clientY = event.clientY;
  this.visibleSidebar1 = true;
}
location() {
  var ruta1 = this.ruta;
  this.ruta = this.router.url
  if (ruta1 != this.ruta) {
    this.visibleSidebar1 = false;
  }

}

logout() {
  localStorage.clear();
  this.router.navigate(['/login']);
}
}
