import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ActualizacionAppService } from 'src/app/services/actualizacion-app.service';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import { isNumber } from 'is-what';
import Swal from 'sweetalert2'
import { ActualizacionApp } from 'src/app/models/actualizacionapp';
import * as _ from "lodash";

@Component({
  selector: 'app-mant-actualizacion-app',
  templateUrl: './mant-actualizacion-app.component.html',
  styleUrls: ['./mant-actualizacion-app.component.css']
})
export class MantActualizacionAppComponent implements OnInit {

  ListadoActualizacionAPP: ActualizacionApp[];

  objActualizacionAPPEdit: ActualizacionApp = new ActualizacionApp();
  objActualizacionAPPSel: ActualizacionApp = new ActualizacionApp();

  activo: boolean;
  baja: boolean;
  dateingreso: Date;
  flgDisplayPrincipal: boolean;
  flgNuevoActualizacionAPP: boolean;
  flgEditarActualizacionAPP: boolean;
  loading: boolean;
  checked: boolean;
  estado: string;

  arrGlobalFilters = ['aplicacion'];

  constructor(
    private messageService: MessageService,
    private serviceactualizacionApp: ActualizacionAppService,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
  ) { }

  ngOnInit() {
    this.flgDisplayPrincipal = true;
    this.blokeo = false;
    this.loading = true;
    this.getactuAppAll()

  }

  getactuAppAll() {

    this.serviceactualizacionApp.getAll().subscribe(data => {
      let orderById = _.orderBy(data, ['id'], ['desc']);
      let orderActivo = _.orderBy(orderById, ['flg_activo'], ['desc']);

      this.ListadoActualizacionAPP = orderActivo.map((o) => { o.flg_estado = Number(o.flg_activo) == 1 ? true : false; return o; });
      this.loading = false;
    })
  }

  editarPerfil(actualizacion: ActualizacionApp) {

    this.blokeo = true;
    this.objActualizacionAPPSel = actualizacion;
    this.getPerfilId();
    this.flgDisplayPrincipal = true;
    this.flgEditarActualizacionAPP = true;
    this.dateingreso = new Date(this.objActualizacionAPPSel.fecha_version)

    if (this.objActualizacionAPPSel.flg_activo != 0) {
      this.checked = true;
      this.estado = "ACTIVO"
    } else if (this.objActualizacionAPPSel.flg_activo == 0) {
      this.checked = false;
      this.estado = "BAJA"
    } else {
    }

  }


  getPerfilId() {
    this.serviceactualizacionApp.getById(this.objActualizacionAPPSel.id).subscribe(
      data => {
        this.objActualizacionAPPEdit = { ...data };
        this.blokeo = false
      });
  }


  bntnuevo() {

    this.flgNuevoActualizacionAPP = true;
    this.flgDisplayPrincipal = false;
    this.flgEditarActualizacionAPP = false;
    this.checked = true;
    this.estado = "ACTIVO"
    this.objActualizacionAPPEdit = new ActualizacionApp();
    this.dateingreso = new Date()

  }

  click() {
    if (this.activo != true) {
      this.objActualizacionAPPEdit.flg_activo = 0;
    } else {
      this.objActualizacionAPPEdit.flg_activo = 1;
      this.baja = false;
    }
  }

  click1() {
    if (this.baja != true) {
      this.objActualizacionAPPEdit.flg_activo = 1;

    } else {
      this.objActualizacionAPPEdit.flg_activo = 0;
      this.activo = false;
    }
  }

  btncancelar() {
    this.flgDisplayPrincipal = true
    this.flgNuevoActualizacionAPP = false
    this.flgEditarActualizacionAPP = false
    this.objActualizacionAPPEdit = new ActualizacionApp();

  }
  check() {
    if (this.checked == true) {
      this.estado = "ACTIVO"
    } else {
      this.estado = "BAJA"
    }


  }



  GuardarVersion() {
    
    if(this.objActualizacionAPPEdit.aplicacion == null || this.objActualizacionAPPEdit.aplicacion.length <= 1){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe Ingresar el Nombre de la Aplicación' });
      this.blokeo = false;
      return false;
    }

    this.blokeo = true;

    let mes_emision: number = this.dateingreso.getMonth();
    mes_emision = mes_emision + 1;
    let dia_emision: number = this.dateingreso.getDate();
    this.objActualizacionAPPEdit.fecha_version = this.dateingreso.getFullYear() + '-' + ('0000' + mes_emision).slice(-2) + '-' + ('0000' + dia_emision).slice(-2)


    if (this.objActualizacionAPPEdit.version == null || this.objActualizacionAPPEdit.version.length <= 1) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe Ingresar la Versión Android' });
      this.blokeo = false;
      return false;
    }


    if (this.checked == true) {
      this.objActualizacionAPPEdit.flg_activo = 1;
    } else {
      this.objActualizacionAPPEdit.flg_activo = 0;
    }

    this.objActualizacionAPPEdit.version = this.objActualizacionAPPEdit.version.trim();
    this.objActualizacionAPPEdit.fecha_version = this.objActualizacionAPPEdit.fecha_version.trim();


    if (isNumber(this.objActualizacionAPPEdit.id)) {
      this.serviceactualizacionApp.update(this.objActualizacionAPPEdit).subscribe(res => {
        this.getactuAppAll();
        this.activo = false;
        this.baja = false;

        this.objActualizacionAPPEdit = new ActualizacionApp();
        this.flgEditarActualizacionAPP = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'La Versión fue actualizado de manera correcta',
          confirmButtonText: 'Aceptar'
        })
      });
    } else {
      this.serviceactualizacionApp.save(this.objActualizacionAPPEdit).subscribe(res => {
        this.getactuAppAll();
        this.activo = false;
        this.baja = false;
        this.objActualizacionAPPEdit = new ActualizacionApp();
        this.flgNuevoActualizacionAPP = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'La versión fue agregado correctamente',
          confirmButtonText: 'Aceptar'
        })
      });

    }
  }

  NuevaVersion(){

    this.dateingreso = new Date()

    this.objActualizacionAPPEdit.id = null;

    var version = new ActualizacionApp;
    version.aplicacion = this.objActualizacionAPPEdit.aplicacion;
    version.version = this.objActualizacionAPPEdit.version;
    version.version_huawei = this.objActualizacionAPPEdit.version_huawei;
    version.version_ios = this.objActualizacionAPPEdit.version_ios;
    version.version_web = this.objActualizacionAPPEdit.version_web;
    version.flg_activo = this.objActualizacionAPPEdit.flg_activo;

    let mes_emision: number = this.dateingreso.getMonth();
    mes_emision = mes_emision + 1;
    let dia_emision: number = this.dateingreso.getDate();
    version.fecha_version = this.dateingreso.getFullYear() + '-' + ('0000' + mes_emision).slice(-2) + '-' + ('0000' + dia_emision).slice(-2)
    

    this.serviceactualizacionApp.save(version).subscribe(res => {
      this.getactuAppAll();
      this.activo = false;
      this.baja = false;
      this.objActualizacionAPPEdit = new ActualizacionApp();
      this.flgNuevoActualizacionAPP = false;
      this.flgDisplayPrincipal = true;
      this.flgEditarActualizacionAPP = false;
      this.blokeo = false;
      Swal.fire({
        type: 'success',
        title: 'La versión fue agregado correctamente',
        confirmButtonText: 'Aceptar'
      })
    });

  }



}
